type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const objectFromEntriesTyped = <T extends ReadonlyArray<readonly [PropertyKey, unknown]>>(
  entries: T,
): { [K in T[number] as K[0]]: K[1] } => {
  return Object.fromEntries(entries) as { [K in T[number] as K[0]]: K[1] };
};

export const objectEntriesTyped = <T extends object>(object: T): Entries<T> => {
  return Object.entries(object) as Entries<T>;
};
