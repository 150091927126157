import { ThemeConfig } from 'antd';

import { ThemeOptions } from './types';
import {
  layoutSizes,
  spaceSizes,
  themeFonts,
  themeInput,
  themeSizes,
  themeWeights,
  themePalette,
} from './variables';

export const getAntTheme = (theme: ThemeOptions): ThemeConfig => ({
  token: {
    fontFamily: themeFonts.fontFamily,
    fontSize: parseFloat(themeSizes.heading4FontSize),
    fontSizeSM: parseFloat(themeSizes.heading4FontSize),
    fontSizeLG: parseFloat(themeSizes.heading4FontSize),
    lineHeight: parseFloat(themeSizes.lineHeightBase),
    borderRadius: parseFloat(themeSizes.borderRadiusBase),

    fontSizeHeading2: parseFloat(themeSizes.heading2FontSize),
    fontSizeHeading3: parseFloat(themeSizes.heading3FontSize),
    fontSizeHeading4: parseFloat(themeSizes.heading4FontSize),
    fontSizeHeading5: parseFloat(themeSizes.heading5FontSize),

    colorPrimary: theme.variables.colorBrand,
    colorFillAlter: theme.variables.colorBgPrimary,
    colorBgBase: theme.variables.colorBgPrimary,
    colorBgLayout: theme.variables.colorBgSecondary,
    colorBgTextHover: theme.variables.colorBrand08,
    colorPrimaryHover: theme.variables.colorHoverOnBrand,
    colorLink: theme.variables.colorLinkBrand,
    colorTextBase: theme.variables.colorTextPrimary,

    controlHeight: parseFloat(themeSizes.heightBase),
    controlHeightSM: parseFloat(themeSizes.heightSM),
    controlHeightLG: parseFloat(themeSizes.heightLG),

    screenXSMin: parseFloat(layoutSizes.screenXS),
    screenXS: parseFloat(layoutSizes.screenXS),
    screenXSMax: parseFloat(layoutSizes.screenSM) - 1,
    screenSMMin: parseFloat(layoutSizes.screenSM),
    screenSM: parseFloat(layoutSizes.screenSM),
    screenSMMax: parseFloat(layoutSizes.screenMD) - 1,
    screenMDMin: parseFloat(layoutSizes.screenMD),
    screenMD: parseFloat(layoutSizes.screenMD),
    screenMDMax: parseFloat(layoutSizes.screenLG) - 1,
    screenLGMin: parseFloat(layoutSizes.screenLG),
    screenLG: parseFloat(layoutSizes.screenLG),
    screenLGMax: parseFloat(layoutSizes.screenXL) - 1,
    screenXLMin: parseFloat(layoutSizes.screenXL),
    screenXL: parseFloat(layoutSizes.screenXL),
    screenXLMax: parseFloat(layoutSizes.screenXXL) - 1,
    screenXXLMin: parseFloat(layoutSizes.screenXXL),
    screenXXL: parseFloat(layoutSizes.screenXXL),
  },
  components: {
    Button: {
      fontWeight: themeWeights.btnFontWeight,
      lineWidth: parseFloat(themeSizes.btnBorderWidth),
      lineHeight: parseFloat(themeSizes.btnLineHeight),
      fontSizeSM: parseFloat(themeSizes.btnFontSize),
      fontSizeLG: parseFloat(themeSizes.btnFontSize),
      paddingInline: parseFloat(themeSizes.btnPaddingHorizontalBase),
    },
    Breadcrumb: {
      itemColor: theme.variables.colorTextSecondary,
      lastItemColor: theme.variables.colorTextPrimary,
      linkColor: theme.variables.colorTextSecondary,
      linkHoverColor: theme.variables.colorHoverOnBrand,
      separatorColor: themePalette.colorIcon,
      separatorMargin: 0,
    },
    Select: {
      optionHeight: parseFloat(themeSizes.selectDropdownHeight),
      optionFontSize: parseFloat(themeSizes.selectDropdownFontSize),
      optionLineHeight: themeSizes.selectDropdownLineHeight,
      singleItemHeightLG: parseFloat(themeSizes.selectSingleItemHeightLg),
    },
    Input: {
      paddingInline: parseFloat(themeInput.paddingHorizontalBase),
      paddingInlineSM: parseFloat(themeInput.paddingHorizontalSm),
      paddingInlineLG: parseFloat(themeInput.paddingHorizontalLg),
      paddingBlock: parseFloat(themeInput.paddingVerticalBase),
      paddingBlockSM: parseFloat(themeInput.paddingVerticalSm),
      paddingBlockLG: parseFloat(themeInput.paddingVerticalLg),
    },
    Pagination: {
      itemSizeSM: parseFloat(themeSizes.paginationItemSizeSm),
    },
    Tabs: {
      horizontalItemPadding: themeSizes.tabsHorizontalPadding,
      horizontalItemPaddingSM: themeSizes.tabsHorizontalPaddingSm,
      horizontalItemPaddingLG: themeSizes.tabsHorizontalPaddingLg,
      horizontalItemGutter: themeSizes.tabsHorizontalMargin,
    },
    Menu: {
      itemBorderRadius: 0,
      controlHeight: parseFloat(themeSizes.heightBase),
      controlHeightLG: parseFloat(themeSizes.heightBase),
      controlHeightSM: parseFloat(themeSizes.heightBase),
    },
    List: {
      emptyTextPadding: spaceSizes.spaceXL,
    },
    Steps: {
      controlHeight: parseFloat(themeSizes.heightSM),
      controlHeightSM: parseFloat(themeSizes.heightSM),
      controlHeightLG: parseFloat(themeSizes.heightSM),
    },
    Spin: {
      controlHeight: parseFloat(themeSizes.heightSM),
    },
  },
});
