export const downloadFile = (file: Blob, name?: string) => {
  const a = document.createElement('a');

  document.body.appendChild(a);

  const blobURL = URL.createObjectURL(file);
  const fileName = name || blobURL.split('/').pop() || 'file';

  a.href = blobURL;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(blobURL);
  a.remove();

  return fileName;
};

export const downloadFileByURL = (url: string, name?: string) =>
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => downloadFile(blob, name));
