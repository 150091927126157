import { Avatar } from 'antd';
import { GroupProps } from 'antd/lib/avatar/group';
import classNames from 'classnames';
import React, { Children, FC, PropsWithChildren, ReactNode } from 'react';

import { UiAvatar, UiAvatarSize } from '..';
import { UiTypography } from '../../typography';
import styles from './UiAvatarGroup.scss';

type UiAvatarAnySize = UiAvatarSize | number;

export interface UiAvatarGroupProps extends Omit<GroupProps, 'size'> {
  maxIcon?: ReactNode;
  onClickSuffix?: () => void;
  bordered?: boolean;
  size?: UiAvatarAnySize;
}

export const UiAvatarGroup: FC<PropsWithChildren<UiAvatarGroupProps>> = (props) => {
  const {
    children,
    className,
    maxCount,
    maxIcon,
    onClickSuffix,
    bordered = true,
    size = UiAvatarSize.S,
    maxPopoverTrigger = 'click',
    maxPopoverPlacement = 'bottom',
    ...restProps
  } = props;
  const childrenCount = Children.count(children);

  const AvatarContainer: FC<PropsWithChildren> = (containerProps) => {
    const { children: node } = containerProps;

    return (
      <Avatar.Group
        size={size}
        maxCount={maxIcon || onClickSuffix ? undefined : maxCount}
        maxPopoverPlacement={maxPopoverPlacement}
        maxPopoverTrigger={maxPopoverTrigger}
        className={classNames(
          styles.uiAvatarGroup,
          {
            [styles.uiAvatarGroup_bordered]: bordered,
          },
          className,
        )}
        {...restProps}
      >
        {node}
      </Avatar.Group>
    );
  };

  if (maxCount && maxCount < childrenCount && (maxIcon || onClickSuffix)) {
    const childrenShow = Children.toArray(children).slice(0, maxCount);

    return (
      <AvatarContainer>
        {childrenShow}
        <UiTypography.Link onClick={onClickSuffix}>
          {maxIcon && <UiAvatar size={size} icon={maxIcon} className={styles.uiAvatarGroup_withIcon} />}
          {!maxIcon && <Avatar size={size}>{`+${childrenCount - maxCount}`}</Avatar>}
        </UiTypography.Link>
      </AvatarContainer>
    );
  }

  return <AvatarContainer>{children}</AvatarContainer>;
};
