import { Rate, RateProps } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';

import FavoriteSvg from '@vkph/ui/svg/favorite.svg';

import { UiIcon } from '../../icon';
import defaultStyles from '../UiRate.scss';
import styles from './UiRateStar.scss';

const iconClassName = classNames(styles.uiRateStar__icon, defaultStyles.uiRate__icon);

export const UiRateStar: FC<RateProps> = (props) => {
  return (
    <Rate
      className={styles.uiRateStar}
      character={<UiIcon width={16} height={16} className={iconClassName} component={FavoriteSvg} />}
      {...props}
    />
  );
};
