import generatePicker from 'antd/es/date-picker/generatePicker';
import { PickerProps as UiDatePickerProps } from 'antd/lib/date-picker/generatePicker';
import rusLocale from 'antd/lib/date-picker/locale/ru_RU';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import React, { FC, useContext } from 'react';

import CalendarSvg from '@vkph/ui/svg/calendar.svg';
import ClearSvg from '@vkph/ui/svg/clear.svg';

import { UiConfigContext } from '../config-provider';
import { UiDatePickerRange } from './range/UiDatePickerRange';

rusLocale.lang.locale = 'ru';

export enum UiDatePickerDataFormat {
  Data,
  DataAndTime,
  Year,
}

export const SHOW_TIME_FORMAT = { format: 'HH:mm' };

export const datePickerDataFormat = {
  [UiDatePickerDataFormat.Data]: ['DD.MM.YYYY', 'D.MM.YYYY', 'DD.M.YYYY'],
  [UiDatePickerDataFormat.DataAndTime]: ['DD.MM.YYYY HH:mm', 'D.MM.YYYY HH:mm', 'DD.M.YYYY HH:mm'],
  [UiDatePickerDataFormat.Year]: ['YYYY'],
};

const DateFnsDatePicker = generatePicker<Date>(dateFnsGenerateConfig);

type UiDatePickerComposition = {
  RangePicker: typeof UiDatePickerRange;
};

export const UiDatePicker: FC<UiDatePickerProps> & UiDatePickerComposition = (props) => {
  const { getPopupContainer } = useContext(UiConfigContext);

  return (
    <DateFnsDatePicker
      getPopupContainer={() => getPopupContainer?.() || document.body}
      allowClear={false}
      locale={rusLocale}
      format={datePickerDataFormat[UiDatePickerDataFormat.Data]}
      placeholder="––.––.––––"
      clearIcon={<ClearSvg />}
      suffixIcon={<CalendarSvg />}
      {...props}
    />
  );
};

UiDatePicker.RangePicker = UiDatePickerRange;
