import { getSplitFileNameExtension } from './getSplitFileNameExtension';

const videoTypes = new Set(['avi', 'mp4', 'mov', 'wmv', 'flv', 'mkv', 'webm']);

/** @derpecated Do not use! Use checkFileMimeType instead. Waiting for https://jira.vk.team/browse/B2BCORE-5976 */
export const isVideoFile = (filename: string) => {
  const { extension } = getSplitFileNameExtension(filename);

  return videoTypes.has(extension);
};
