import { Radio } from 'antd';
import { SpaceSize } from 'antd/lib/space';
import isObject from 'lodash/isObject';
import React, { FC, MouseEventHandler, cloneElement, PropsWithChildren, Children, useMemo } from 'react';

import { useToggle } from '../../../hooks';
import { getSizeGutterBySizeFullName } from '../../config-provider';
import { UiRadioChangeEventHandler } from '../../radio';
import { UiToggleButton, UiToggleButtonProps } from '../UiToggleButton';
import styles from '../UiToggleButton.scss';

export interface UiToggleButtonGroupProps {
  items?: UiToggleButtonProps[];
  value?: UiToggleButtonProps['value'];
  spaceSize?: SpaceSize;
  size?: UiToggleButtonProps['size'];
  onChange?: (value: UiToggleButtonProps['value']) => void;
}

export const UiToggleButtonGroup: FC<PropsWithChildren<UiToggleButtonGroupProps>> = (props) => {
  const { items, value, spaceSize, size, children, onChange } = props;

  const toggleValues = useMemo(() => {
    const values: UiToggleButtonProps['value'][] = [value, null];

    if (items) {
      values.push(...items.map((item) => item.value));
    } else if (children) {
      const childrenToggleValues = Children.toArray(children).reduce(
        (acc: UiToggleButtonProps['value'][], curr) => {
          return isObject(curr) && 'props' in curr && curr.props.value !== undefined
            ? [...acc, curr.props.value]
            : acc;
        },
        [],
      );

      values.push(...childrenToggleValues);
    }

    return Array.from(new Set(values).values());
  }, [value, items, children]);

  const [currentToggleValue, toggleValue] = useToggle<UiToggleButtonProps['value']>(toggleValues);

  const handleChange: UiRadioChangeEventHandler = (event) => {
    const valueToggle = event.target.value;

    toggleValue(valueToggle);
    onChange?.(valueToggle);
  };

  const handleClearValue: MouseEventHandler<HTMLInputElement> = (event) => {
    if (event.currentTarget.value === currentToggleValue) {
      toggleValue(null);
      onChange?.(null);
    }
  };

  return (
    <Radio.Group
      optionType="button"
      onChange={handleChange}
      value={currentToggleValue}
      size={size}
      className={styles.uiToggleGroup}
      style={{
        gap: spaceSize,
      }}
    >
      {Children.toArray(children).map((child) => {
        if (!isObject(child) || !('props' in child)) {
          return child;
        }

        return cloneElement(child, {
          onClick: handleClearValue,
          size: getSizeGutterBySizeFullName(size),
          checked: currentToggleValue === child.props.value,
          className: child.props.value ? styles.uiToggleButton : '',
        });
      })}
      {!children &&
        items?.map((item) => (
          <UiToggleButton
            key={item.value}
            {...item}
            onClick={handleClearValue}
            checked={currentToggleValue === item.value}
          />
        ))}
    </Radio.Group>
  );
};
