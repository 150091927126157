import React, { CSSProperties, FC, useMemo } from 'react';

export enum UiRenderType {
  NullElement = 'NullElement',
  DisplayNone = 'DisplayNone',
  VisibilityHidden = 'VisibilityHidden',
}

/*
 * UiRender - простой conditional rendering компонент
 * У которого не должно быть дополнительной логики и стилей
 */
export interface UiRenderProps extends Omit<React.HTMLAttributes<HTMLElement>, 'className' | 'style'> {
  type: UiRenderType;
  visible?: boolean;
}

export const UiRender: FC<UiRenderProps> = (props) => {
  const { children, type: currentType, visible = true } = props;
  const isCurrentType = (type: UiRenderType) => currentType === type;
  const componentProps = useMemo(
    () => ({
      style: (isCurrentType(UiRenderType.VisibilityHidden)
        ? { visibility: visible ? 'visible' : 'hidden' }
        : { display: visible ? 'inherit' : 'none' }) as CSSProperties,
    }),
    [isCurrentType, visible],
  );

  if (isCurrentType(UiRenderType.NullElement)) {
    return visible ? <>{children}</> : null;
  }

  return <div {...componentProps}>{children}</div>;
};
