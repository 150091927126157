// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5bffc4c0_ui-descriptions-item_space>.ant-space-item{display:contents}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/descriptions/item/UiDescriptionsItem.scss"],"names":[],"mappings":"AAGI,sDACE,gBAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ui-descriptions-item {\n  &_space {\n    > :global(.ant-space-item) {\n      display: contents;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-descriptions-item_space": "_5bffc4c0_ui-descriptions-item_space",
	"uiDescriptionsItem_space": "_5bffc4c0_ui-descriptions-item_space"
};
export default ___CSS_LOADER_EXPORT___;
