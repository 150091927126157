import { Empty, EmptyProps as UiEmptyProps } from 'antd';
import React, { FC } from 'react';

import SunSvg from '../../svg/sun.svg';
import { UiIcon } from '../icon';
import { UiEmptyFeed } from './feed/UiEmptyFeed';
import { UiEmptyFrame } from './frame/UiEmptyFrame';

type UiEmptyComposition = {
  Feed: typeof UiEmptyFeed;
  Frame: typeof UiEmptyFrame;
};

export const UiEmpty: FC<UiEmptyProps> & UiEmptyComposition = (props) => {
  return <Empty image={<UiIcon component={SunSvg} width={120} height={104} />} {...props} />;
};

UiEmpty.Feed = UiEmptyFeed;
UiEmpty.Frame = UiEmptyFrame;
