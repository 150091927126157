import { UiMentionProps } from './UiMentions';
import uiMentionsInputStyles from './input/UiMentionsInput.scss';
import { renderSuggestion } from './suggestion/renderSuggestion';

export * from './suggestion/renderSuggestion';
export * from './input/UiMentionsInput';
export * from './UiMentions';
export type { UiMentionInputSearchProps } from './input/search/UiMentionInputSearch';

export enum MentionSuffixType {
  Tag = 'tag',
  User = '@/user',
}

export type GetTagMentionParams = {
  id: string;
  name: string;
  type: MentionSuffixType;
};

export const getMention = ({ id, name, type }: GetTagMentionParams): string => `@[${name}](${type}/${id})`;

export const defaultUserMentionsProps: UiMentionProps = {
  data: [],
  trigger: '@',
  markup: '[__display__](@/user/__id__)', // TODO: тут @ -- символ нужен для распознования что это mention в libs/components/Markdown!
  displayTransform: (_, display) => `${display}`,
  className: uiMentionsInputStyles.uiMentionsInput__mention,
  renderSuggestion,
};
