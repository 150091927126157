import classNames from 'classnames';
import React, { FC, PropsWithChildren, useContext, HTMLAttributes, CSSProperties } from 'react';

import ArrowBackSVG from '@vkph/ui/svg/arrow-back.svg';
import CloseSVG from '@vkph/ui/svg/close.svg';
import SuccessSvg from '@vkph/ui/svg/success.svg';

import { MAX_BADGE_COUNT, UiBadge } from '../../../badge';
import { UiButton } from '../../../button';
import { UiConfigProviderSizeType, getSizeClsBySizeFullName } from '../../../config-provider';
import { UiIcon } from '../../../icon';
import { UiTruncateMarkup, UiTruncateMarkupProps } from '../../../truncate-markup';
import { UiTypography } from '../../../typography';
import { UiModalContext } from '../context';
import { UiModalSteps } from '../typings';
import styles from './UiModalHeader.scss';

const ccn = classNames.bind(styles);

export interface UiModalHeaderProps {
  className?: string;
  hasBottomBorder?: boolean;
}

export interface UiModalHeaderTitleProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'style' | 'className'>,
    Pick<UiTruncateMarkupProps, 'truncate' | 'ellipsis'> {
  wrapperClassName?: string;
  noCloseIcon?: boolean;
  steps: UiModalSteps;
  currentStep?: number;
  onStepBack?: (nextStep: number) => void;
  showZeroBadge?: boolean;
  size?: UiConfigProviderSizeType;
  textStyle?: CSSProperties;
}

interface UiModalHeaderButtonsProps {
  movedRight?: boolean;
}

type ModalHeaderComponent = FC<PropsWithChildren>;
type ModalHeaderSuccessComponent = ModalHeaderComponent;
type ModalHeaderTitleComponent = FC<PropsWithChildren<UiModalHeaderTitleProps>>;
type ModalHeaderButtonsComponent = FC<PropsWithChildren<UiModalHeaderButtonsProps>>;
type ModalHeaderUnderTitleComponent = ModalHeaderComponent;

type HeaderComposition = {
  Success: ModalHeaderSuccessComponent;
  Title: ModalHeaderTitleComponent;
  Buttons: ModalHeaderButtonsComponent;
  UnderTitle: ModalHeaderUnderTitleComponent;
};

export type UiModalHeaderComponent = FC<PropsWithChildren<UiModalHeaderProps>> & HeaderComposition;

export const UiModalHeader: UiModalHeaderComponent = (props) => {
  const { children, className, hasBottomBorder = false } = props;

  const modalHeaderClassName = ccn(
    styles.uiModalHeader,
    {
      [styles.uiModalHeader_withBorder]: hasBottomBorder,
    },
    className,
  );

  return <div className={modalHeaderClassName}>{children}</div>;
};

const UiModalHeaderSuccess: ModalHeaderSuccessComponent = (props) => {
  const { children } = props;

  return (
    <UiModalHeader {...props} className={styles.uiModalHeader__success}>
      <UiIcon component={SuccessSvg} width={20} height={20} />
      {children}
    </UiModalHeader>
  );
};

export const UiModalHeaderTitle: ModalHeaderTitleComponent = (props) => {
  const {
    currentStep = 1,
    onStepBack,
    wrapperClassName,
    noCloseIcon,
    className,
    steps,
    truncate = false,
    ellipsis,
    children,
    showZeroBadge = false,
    size,
    style,
    textStyle,
  } = props;
  const { onClose } = useContext(UiModalContext);
  const sizeCls = getSizeClsBySizeFullName(size || 'middle');

  const titleWrapperClassName = ccn(
    styles.uiModalHeader__titleWrapper,
    {
      [`${styles.uiModalHeader__titleWrapper}_${sizeCls}`]: sizeCls,
      [styles.uiModalHeader__titleWrapper_hasIcon]: !noCloseIcon,
    },
    wrapperClassName,
  );
  const titleClassName = ccn(styles.uiModalHeader__titleText, className);

  const closeIconClassName = ccn(styles.uiModalHeader__closeIcon, {
    [`${styles.uiModalHeader__closeIcon}_${sizeCls}`]: sizeCls,
  });

  const currentStepTitle = steps[currentStep]?.title;
  const currentBadgeValue = steps[currentStep]?.badgeValue;
  const hasBadge = currentBadgeValue !== undefined && currentBadgeValue !== null;

  const shouldRenderStepBack = onStepBack && currentStep > 1;
  const handleStepBack = () => onStepBack?.(currentStep - 1);

  return (
    <div className={titleWrapperClassName} style={style}>
      {onClose && !noCloseIcon && (
        <UiButton
          size="middle"
          type="link"
          onClick={onClose}
          className={closeIconClassName}
          icon={<CloseSVG />}
        />
      )}
      {shouldRenderStepBack && (
        <UiButton
          type="link"
          onClick={handleStepBack}
          className={styles.uiModalHeader__stepBackIcon}
          icon={<ArrowBackSVG />}
        />
      )}

      <UiTypography.Text
        className={classNames(styles.uiModalHeader__title, titleClassName)}
        style={textStyle}
      >
        <UiTruncateMarkup truncate={truncate} ellipsis={ellipsis}>
          {currentStepTitle}
        </UiTruncateMarkup>
      </UiTypography.Text>

      {hasBadge && (
        <UiBadge
          className={styles.uiModalHeader__badge}
          count={currentBadgeValue}
          showZero={showZeroBadge}
          overflowCount={MAX_BADGE_COUNT}
        />
      )}

      {children}
    </div>
  );
};

const UiModalHeaderButtons: ModalHeaderButtonsComponent = (props) => {
  const { children, movedRight } = props;

  return (
    <div
      className={classNames(styles.uiModalHeader__buttons, {
        [styles.uiModalHeader__buttons_movedRight]: movedRight,
      })}
    >
      {children}
    </div>
  );
};

const UiModalHeaderUnderTitle: ModalHeaderUnderTitleComponent = ({ children }) => (
  <div className={styles.uiModalHeader__underTitle}>{children}</div>
);

UiModalHeader.Success = UiModalHeaderSuccess;
UiModalHeader.Title = UiModalHeaderTitle;
UiModalHeader.Buttons = UiModalHeaderButtons;
UiModalHeader.UnderTitle = UiModalHeaderUnderTitle;
