// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._16e2002f_empty-feed{display:flex;flex-direction:column;align-items:center;border-radius:8px;padding:24px}._16e2002f_empty-feed_empty-padding[class]{padding:0}._16e2002f_empty-feed_bordered{border:1px dashed #d5d8de}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/empty/feed/UiEmptyFeed.scss","webpack://./../../libs/ui/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AACA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,iBCCa,CAAA,YAJD,CDMZ,2CACE,SAAA,CAGF,+BACE,yBAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.empty-feed {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  border-radius: $borderRadius;\n  padding: $baseSpacing;\n\n  &_empty-padding[class] {\n    padding: 0;\n  }\n\n  &_bordered {\n    border: $dashedBorder;\n  }\n}\n","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$baseIconSize: get('themeSizes.baseIconSize');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$borderRadius: 8px; // TODO: вынести в тему\n$borderRadiusHalf: 4px; // TODO: вынести в тему\n$borderRadiusQuarter: 2px; // TODO: вынести в тему\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: getNumberToPx(get('spaceSizes.space2XS'));\n\n$space2XS: getNumberToPx(get('spaceSizes.space2XS'));\n$spaceXS: getNumberToPx(get('spaceSizes.spaceXS'));\n$spaceS: getNumberToPx(get('spaceSizes.spaceS'));\n$spaceM: getNumberToPx(get('spaceSizes.spaceM'));\n$spaceL: getNumberToPx(get('spaceSizes.spaceL'));\n$spaceXL: getNumberToPx(get('spaceSizes.spaceXL'));\n$space2XL: getNumberToPx(get('spaceSizes.space2XL'));\n$space3XL: getNumberToPx(get('spaceSizes.space3XL'));\n$space4XL: getNumberToPx(get('spaceSizes.space4XL'));\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty-feed": "_16e2002f_empty-feed",
	"emptyFeed": "_16e2002f_empty-feed",
	"empty-feed_empty-padding": "_16e2002f_empty-feed_empty-padding",
	"emptyFeed_emptyPadding": "_16e2002f_empty-feed_empty-padding",
	"empty-feed_bordered": "_16e2002f_empty-feed_bordered",
	"emptyFeed_bordered": "_16e2002f_empty-feed_bordered"
};
export default ___CSS_LOADER_EXPORT___;
