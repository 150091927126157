import { Drawer, DrawerProps } from 'antd';
import React, { FC } from 'react';

import styles from './UiDrawer.scss';

export interface UiDrawerProps extends Omit<DrawerProps, 'closeIcon'> {
  withCustomCloseIcon?: boolean;
}

export const UiDrawer: FC<UiDrawerProps> = (props) => {
  const { withCustomCloseIcon, extra, onClose, ...restProps } = props;

  return <Drawer className={styles.uiDrawer} extra={withCustomCloseIcon ? extra : null} {...restProps} />;
};
