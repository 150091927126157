import { Row, RowProps } from 'antd';
import React, { FC, RefAttributes } from 'react';

import { usePaddingStyle, PaddingSpaces } from '../../../hooks';

export interface UiRowProps extends RowProps, RefAttributes<HTMLDivElement> {
  padding?: number | PaddingSpaces;
}

export const UiRow: FC<UiRowProps> = (props) => {
  const { padding = [], style, ...rest } = props;

  const paddingValue = Array.isArray(padding) ? padding : [padding];
  const paddings = usePaddingStyle(paddingValue as PaddingSpaces);

  const styleProps = {
    ...(paddingValue.length > 0 && paddings),
    ...style,
  };

  return <Row {...rest} style={styleProps} />;
};
