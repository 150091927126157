import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import { UiCard, UiCardProps } from '..';
import { AbstractAbsoluteShiftPlacement, useAbsolutePlacementShift } from '../../../hooks';
import styles from './UiCardOverlay.scss';

type Props = UiCardProps & AbstractAbsoluteShiftPlacement;

export const UiCardOverlay: FC<PropsWithChildren<Props>> = (props) => {
  const { style, className, children, placement, shift, ...restProps } = props;
  const { placementStyles, placementCls } = useAbsolutePlacementShift({
    placement,
    shift,
  });

  return (
    <UiCard
      {...restProps}
      emptyPadding
      style={{ ...style, ...placementStyles }}
      className={classNames(styles.uiCardOverlay, placementCls, className)}
      size="default"
    >
      {children}
    </UiCard>
  );
};
