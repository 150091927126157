import classNames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import styles from './UiFormSection.scss';

interface Props extends HTMLAttributes<HTMLElement> {
  hasTopBorder?: boolean;
  hasBottomBorder?: boolean;
}

/** @deprecated TODO: B2BCORE-9773 удалить и не использовать */
export const UiFormSection: FC<Props> = (props) => {
  const { children, className, hasTopBorder, hasBottomBorder, style, ...sectionProps } = props;

  // TODO: переделать section в пользу fieldset
  return (
    <section
      style={style}
      className={classNames(
        styles.uiFormSection,
        {
          [styles.uiFormSection_borderedTop]: hasTopBorder,
          [styles.uiFormSection_borderedBottom]: hasBottomBorder,
        },
        className,
      )}
      {...sectionProps}
    >
      {children}
    </section>
  );
};
