import { useEffect, useState, useCallback, useRef } from 'react';
import screenfull from 'screenfull';

interface Params {
  onChange?: (event: Event) => void;
  onError?: (event: Event) => void;
  options?: FullscreenOptions;
}

export type UseFullscreenResult = ReturnType<typeof useFullscreen>;

export function useFullscreen(params: Params = {}) {
  const { onChange, onError, options } = params;
  const ref = useRef<HTMLDivElement | null>(null);

  const [isAvailable] = useState(() => screenfull.isEnabled);
  const [isEnabled, setIsEnabled] = useState(false);

  const onOpen = useCallback(async () => {
    if (ref?.current) {
      await screenfull.request(ref.current, options);
    } else {
      console.warn('Cannot get the element from ref');
    }
  }, [ref, options]);

  const onClose = useCallback(async () => {
    await screenfull.exit();
  }, []);

  const onToggle = useCallback(async () => {
    const toggle = screenfull.element ? onClose : onOpen;

    await toggle();
  }, [onOpen, onClose]);

  const onChangeHandler = useCallback(
    (event: Event) => {
      const { element } = screenfull;

      setIsEnabled(Boolean(element));
      onChange?.(event);
    },
    [onChange],
  );

  const onErrorHandler = useCallback(
    (event: Event) => {
      const { element } = screenfull;

      setIsEnabled(Boolean(element));
      onError?.(event);
    },
    [onError],
  );

  // Attach event listeners
  useEffect(() => {
    screenfull.on('change', onChangeHandler);
    screenfull.on('error', onErrorHandler);

    return () => {
      screenfull.off('change', onChangeHandler);
      screenfull.off('error', onErrorHandler);
    };
  }, [onChangeHandler, onErrorHandler]);

  return {
    isAvailable,
    isEnabled,
    onOpen,
    onClose,
    onToggle,
    ref,
  };
}
