import classNames from 'classnames';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

import { useSpace } from '../../../../hooks';
import { UiConfigProviderSizeType, getSizeClsBySizeFullName } from '../../../config-provider';
import { UiRow, UiCol } from '../../../grid';
import styles from './UiModalFooter.scss';

const ccn = classNames.bind(styles);

export interface UiModalFooterProps {
  className?: string;
  hasTopBorder?: boolean;
  size?: UiConfigProviderSizeType;
}

export interface UiModalFooterButtonsProps {
  className?: string;
  style?: React.CSSProperties;
}

interface UiModalFooterButtonsConfirmProps {
  OkBtn?: ReactNode;
  CancelBtn?: ReactNode;
}

type FooterComposition = {
  Buttons: FC<PropsWithChildren<UiModalFooterButtonsProps>>;
  Confirm: FC<PropsWithChildren<UiModalFooterButtonsConfirmProps>>;
};

export type ModalFooterComponent = FC<PropsWithChildren<UiModalFooterProps>> & FooterComposition;

const UiModalFooterButtonsConfirm = ({ OkBtn, CancelBtn }: UiModalFooterButtonsConfirmProps) => {
  const { spaceS, spaceL } = useSpace();

  return (
    <UiRow gutter={spaceL}>
      <UiCol span={spaceS}>{OkBtn}</UiCol>
      <UiCol span={spaceS}>{CancelBtn}</UiCol>
    </UiRow>
  );
};

export const UiModalFooter: ModalFooterComponent = (props) => {
  const { children, className, size, hasTopBorder = true } = props;
  const sizeCls = getSizeClsBySizeFullName(size || 'middle');

  const modalFooterClassName = ccn(
    styles.uiModalFooter,
    {
      [styles.uiModalFooter_topBorder]: hasTopBorder,
      [`${styles.uiModalFooter}_${sizeCls}`]: sizeCls,
    },
    className,
  );

  return <div className={modalFooterClassName}>{children}</div>;
};

const UiModalFooterButtons: FC<PropsWithChildren<UiModalFooterButtonsProps>> = (props) => {
  const { children, className, style } = props;

  return (
    <div className={classNames(styles.uiModalFooter__buttons, className)} style={style}>
      {children}
    </div>
  );
};

UiModalFooter.Buttons = UiModalFooterButtons;
UiModalFooter.Confirm = UiModalFooterButtonsConfirm;
