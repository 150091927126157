import classNames from 'classnames';
import React, { FC } from 'react';

import {
  UiTypographyTextType,
  UiTypography,
  UiTypographyTextTypes,
  UiTypographyTextProps,
} from '../../typography';
import { UiIcon, UiIconProps } from '../UiIcon';
import styles from './UiIconLabel.scss';

export interface UiIconLabelProps extends Omit<UiIconProps, 'component'> {
  style?: UiTypographyTextProps['style'];
  iconStyle?: UiIconProps['style'];
  size?: number;
  shift?: number;
  label?: string;
  strong?: boolean;
  active?: boolean;
  type?: UiTypographyTextType;
  component: SvgrComponent;
  wrapperClassName?: string;
}

export const UiIconLabel: FC<UiIconLabelProps> = (props) => {
  const {
    type = UiTypographyTextTypes.Secondary,
    size = 20,
    shift = 8,
    label,
    strong,
    style,
    wrapperClassName,
    children,
    iconStyle,
    active,
    ...iconProps
  } = props;

  return (
    <UiTypography.Text
      type={type}
      style={style}
      strong={strong}
      className={classNames(styles.uiIconLabel, wrapperClassName, {
        [styles.uiIconLabel_active]: active,
      })}
    >
      <UiIcon width={size} height={size} {...iconProps} style={{ ...iconStyle, marginRight: shift }} />
      {children || label}
    </UiTypography.Text>
  );
};
