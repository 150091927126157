import React, { FC, useState, PropsWithChildren, useEffect, useMemo } from 'react';

import MobileArrowCollapseSvg from '@vkph/ui/svg/arrow-collapse-2.svg';
import ArrowCollapseSvg from '@vkph/ui/svg/arrow-collapse.svg';
import MobileArrowExpandSvg from '@vkph/ui/svg/arrow-expand-2.svg';
import ArrowExpandSvg from '@vkph/ui/svg/arrow-expand.svg';

import { useElementSize, usePaddingStyle, useSpace } from '../../../hooks';
import { UiButton } from '../../button';
import { UiRow } from '../../grid';
import { UiIcon } from '../../icon';
import styles from './UiCollapseContent.scss';

type Props = {
  height?: number;
  isExpandable?: boolean;
  compact?: boolean;
};

export const UiCollapseContent: FC<PropsWithChildren<Props>> = (props) => {
  const { children, height = 200, isExpandable = true, compact = false } = props;

  const [isCollapsible, setIsCollapsible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState(height);
  const { ref, height: totalHeight } = useElementSize();
  const { spaceL, spaceM, spaceXS } = useSpace();
  const buttonWrapperPadding = usePaddingStyle([0, compact ? spaceM : 0]);
  const moreButtonText = isExpanded ? 'Свернуть' : 'Показать полностью';

  useEffect(() => {
    setMaxHeight((currentValue) => Math.max(currentValue, totalHeight));
    setIsCollapsible(totalHeight > height * 2);
  }, [totalHeight]);

  const buttonIcon = useMemo(() => {
    if (isExpanded) {
      return compact ? MobileArrowCollapseSvg : ArrowCollapseSvg;
    }

    return compact ? MobileArrowExpandSvg : ArrowExpandSvg;
  }, [isExpanded, compact]);

  if (!isCollapsible) {
    return <div ref={ref}>{children}</div>;
  }

  return (
    <>
      <div
        ref={ref}
        className={styles.uiCollapseContent}
        style={{ maxHeight: isExpanded ? maxHeight : height }}
      >
        {children}
        {!compact && !isExpanded && <div className={styles.uiCollapseContent__overlay} />}
      </div>
      {isExpandable && (
        <UiRow style={buttonWrapperPadding}>
          <UiButton
            block
            size="large"
            type={compact ? 'link-secondary' : 'secondary'}
            icon={<UiIcon component={buttonIcon} width={20} height={20} />}
            label={compact ? undefined : moreButtonText}
            style={{ marginTop: compact ? spaceXS : spaceL, width: '100%' }}
            onClick={() => setIsExpanded((currentValue) => !currentValue)}
          />
        </UiRow>
      )}
    </>
  );
};
