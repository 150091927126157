import { Layout } from 'antd';
import React, { FC, PropsWithChildren, HTMLAttributes, CSSProperties } from 'react';

import { UiCol, UiRow } from '../../grid';
import { useLayout } from '../hooks';

type Props = HTMLAttributes<HTMLDivElement>;

export const LayoutMain: FC<PropsWithChildren<Props>> = (props) => {
  const { children, style, ...rest } = props;
  const { outerGutter, innerGutter } = useLayout();

  const mergedStyle: CSSProperties = {
    margin: `${innerGutter} ${outerGutter} ${outerGutter} ${outerGutter}`,
    ...style,
  };

  return (
    <Layout {...rest} style={mergedStyle}>
      <UiCol flex="auto">
        <UiRow style={{ gap: innerGutter }} wrap={false} justify="center">
          {children}
        </UiRow>
      </UiCol>
    </Layout>
  );
};
