import { Form as AntForm, FormInstance } from 'antd';
import { FormProps as AntFormProps } from 'antd/es/form';
import React, { PropsWithChildren, ForwardRefRenderFunction } from 'react';

import { UiFormSection } from './section/UiFormSection';

export interface UiFormComposition {
  Item: typeof AntForm.Item;
  useForm: typeof AntForm.useForm;
  useWatch: typeof AntForm.useWatch;
  useFormInstance: typeof AntForm.useFormInstance;
  List: typeof AntForm.List;
  Provider: typeof AntForm.Provider;
  /** @deprecated TODO: B2BCORE-9773 удалить и не использовать */
  Section: typeof UiFormSection;
}

export type UiFormInstance = FormInstance;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UiFormProps<Values = any> = PropsWithChildren<AntFormProps<Values>>;
interface UiFormPropsWithRef<Values> extends UiFormProps {
  ref?: React.Ref<FormInstance<Values>>;
}

const InternalUiForm: ForwardRefRenderFunction<FormInstance, UiFormProps> = (props, ref) => {
  return <AntForm ref={ref} requiredMark={false} colon={false} {...props} />;
};

export const UiForm = React.forwardRef<FormInstance, UiFormProps>(InternalUiForm) as unknown as (<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Values = any,
>(
  props: UiFormPropsWithRef<Values>,
) => React.ReactElement) &
  UiFormComposition;

UiForm.Item = AntForm.Item;
UiForm.useForm = AntForm.useForm;
UiForm.useWatch = AntForm.useWatch;
UiForm.List = AntForm.List;
UiForm.Provider = AntForm.Provider;
UiForm.Section = UiFormSection;
UiForm.useFormInstance = () => AntForm.useFormInstance();
