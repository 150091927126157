import { CSSProperties, useMemo } from 'react';

export type PaddingVariants = Pick<CSSProperties, 'padding'>;

export type PaddingSpaces = (string | number)[];

export const usePaddingStyle = (spaces?: PaddingSpaces): PaddingVariants => {
  return useMemo(() => {
    if (!spaces || spaces.length === 0) {
      return { padding: 0 };
    }

    const cssSpaces = spaces.map((space) => {
      if (typeof space === 'number') {
        return `${space}px`;
      }

      return space;
    });

    let paddingValue;

    switch (cssSpaces.length) {
      case 1:
        [paddingValue] = cssSpaces;
        break;
      case 2:
        paddingValue = `${cssSpaces[0]} ${cssSpaces[1]}`;
        break;
      case 3:
        paddingValue = `${cssSpaces[0]} ${cssSpaces[1]} ${cssSpaces[2]}`;
        break;
      case 4:
        paddingValue = `${cssSpaces[0]} ${cssSpaces[1]} ${cssSpaces[2]} ${cssSpaces[3]}`;
        break;
      default:
        paddingValue = 0;
    }

    return { padding: paddingValue };
  }, [spaces]);
};
