import classNames from 'classnames';
import React, { FC } from 'react';

import { UiTypographyTextProps } from '../UiTypography';
import { UiTypographyText } from '../text/UiTypographyText';
import styles from './UiTypographyFootnote.scss';

export const UiTypographyFootnote: FC<UiTypographyTextProps> = (props) => {
  const { className, ...restProps } = props;
  const classNameStyles = classNames(styles.uiTypographyFootnote, className);

  return <UiTypographyText {...restProps} className={classNameStyles} />;
};
