// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3dd08b7f_ui-slider{height:18px;width:100%}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/slider/UiSlider.scss"],"names":[],"mappings":"AACA,qBACE,WAAA,CACA,UAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ui-slider {\n  height: 18px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-slider": "_3dd08b7f_ui-slider",
	"uiSlider": "_3dd08b7f_ui-slider"
};
export default ___CSS_LOADER_EXPORT___;
