import { useCallback, useEffect, useState } from 'react';

import { UiUploadBeforeUploadHandler, UiUploadOnPickFilesHandler, UiUploadOriginFile } from '..';

export type ModifyBeforeUploadParams = {
  beforeUpload?: UiUploadBeforeUploadHandler;
  onPickFiles?: UiUploadOnPickFilesHandler;
};

export type UseModifyBeforeUpload = {
  onBeforeUpload: UiUploadBeforeUploadHandler;
};

export const useModifyBeforeUpload = (params: ModifyBeforeUploadParams): UseModifyBeforeUpload => {
  const { beforeUpload, onPickFiles } = params;
  const [files, setFiles] = useState<UiUploadOriginFile[]>([]);

  const onBeforeUpload = useCallback<UiUploadBeforeUploadHandler>(
    (file: UiUploadOriginFile, fileList: UiUploadOriginFile[]) => {
      setFiles(fileList);

      if (beforeUpload) {
        return beforeUpload(file, fileList);
      }

      return !onPickFiles;
    },
    [beforeUpload, setFiles],
  );

  useEffect(() => {
    if (files.length > 0) {
      onPickFiles?.(files);
    }
  }, [files]);

  return { onBeforeUpload };
};
