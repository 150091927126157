import { useReducer, SetStateAction } from 'react';

export const useToggle = <Value = boolean>(values: Value[]) => {
  const [[option], toggle] = useReducer((state: Value[], action: SetStateAction<Value>) => {
    const value = action instanceof Function ? action(state[0]) : action;
    const index = Math.abs(state.indexOf(value));

    return state.slice(index).concat(state.slice(0, index));
  }, values as Value[]);

  return [option, toggle as (value?: Value) => void] as const;
};
