import classNames from 'classnames';
import React, { FC } from 'react';

import { UiIcon, UiIconProps } from '../UiIcon';
import styles from './UiIconCircle.scss';

export const UiIconCircle: FC<UiIconProps> = (props) => {
  const { className, ...restProps } = props;

  return <UiIcon {...restProps} className={classNames(styles.uiIconCircle, className)} />;
};
