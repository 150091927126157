import classNames from 'classnames';
import React, { FC, CSSProperties, useState } from 'react';
import TruncateMarkup, { TruncateProps } from 'react-truncate-markup';

import { UiTooltip, UiTooltipProps } from '../tooltip';
import styles from './UiTruncateMarkup.scss';

export interface UiTruncateMarkupProps extends TruncateProps {
  contentStyle?: CSSProperties;
  truncate?: boolean;
  className?: string;
  wrapperClassName?: string;
  tooltipProps?: UiTooltipProps;
  onTruncate?: (isTruncated: boolean) => void;
}

export interface UiTruncateMarkupComposition extends FC<UiTruncateMarkupProps> {
  Atom: typeof TruncateMarkup.Atom;
}

export const UiTruncateMarkup: UiTruncateMarkupComposition = (props) => {
  const [isTruncated, setIsTruncated] = useState(false);

  const {
    children,
    lines = 1,
    contentStyle,
    truncate,
    className,
    tooltipProps,
    onTruncate,
    wrapperClassName,
    ...otherProps
  } = props;

  if (!truncate) {
    return <>{children}</>;
  }

  const handleTruncate = (truncated: boolean) => {
    setIsTruncated(truncated);

    if (onTruncate) {
      onTruncate(truncated);
    }
  };

  const truncateElement = (
    <TruncateMarkup key={null} lines={lines} onTruncate={handleTruncate} {...otherProps}>
      <div style={contentStyle} className={classNames(styles.truncateMarkup, className)}>
        {children}
      </div>
    </TruncateMarkup>
  );

  if (!tooltipProps?.title || !isTruncated) {
    return truncateElement;
  }

  return (
    <UiTooltip {...tooltipProps}>
      <div className={wrapperClassName}>{truncateElement}</div>
    </UiTooltip>
  );
};

UiTruncateMarkup.Atom = TruncateMarkup.Atom;
