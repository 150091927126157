import { Space, SpaceProps } from 'antd';
import { SpaceSize } from 'antd/es/space';
import { NoCompactStyle } from 'antd/es/space/Compact';
import classNames from 'classnames';
import React, { FC, useMemo } from 'react';

import { useSpace } from '../../hooks';
import styles from './UiSpace.scss';

export type { SpaceCompactProps as UiSpaceCompactProps } from 'antd/es/space/Compact';
export {
  useCompactItemContext,
  SpaceCompactItemContext as UiSpaceCompactItemContext,
} from 'antd/es/space/Compact'; /** @note Импорт должен быть именно из antd/es, в antd/lib нет _инициализации_ контекста для antd */

export interface UiSpaceComposition {
  Compact: typeof Space.Compact;
  NoCompactStyle: typeof NoCompactStyle;
}

export interface UiSpaceProps extends SpaceProps {
  full?: boolean;
}

export const UiSpace: FC<UiSpaceProps> & UiSpaceComposition = (props) => {
  const { full, className, size = 'small', ...restProps } = props;
  const { spaceXS, spaceM, spaceXL } = useSpace();
  const sizes = {
    small: spaceXS,
    middle: spaceM,
    large: spaceXL,
  };

  const getSizeFromSizes = (sizeValue: SpaceSize) => {
    return typeof sizeValue === 'string' && sizes[sizeValue] ? sizes[sizeValue] : sizeValue || 0;
  };

  const sizeProp: SpaceProps['size'] = useMemo(() => {
    if (Array.isArray(size)) {
      const [horizontalSize, verticalSize] = size;

      return [getSizeFromSizes(horizontalSize), getSizeFromSizes(verticalSize)];
    }

    return getSizeFromSizes(size);
  }, [size]);

  return (
    <Space
      size={sizeProp}
      className={classNames({ [styles.uiSpace_full]: full }, className)}
      {...restProps}
    />
  );
};

UiSpace.Compact = Space.Compact;
UiSpace.NoCompactStyle = NoCompactStyle;
