import { Modal as AntModal } from 'antd';
import React from 'react';

import { useApp } from '../../../hooks';
import { UiModalFooter } from '../modal/footer';
import { getAntdModalFuncProps, getAntdModalSuccessFuncProps, getAntdModalWarningFuncProps } from './helpers';
import {
  ModalWithCustomUpdateFunc,
  ModalConfirmProps,
  ModalFuncProps,
  UiModalBaseType,
  Modal,
} from './types';

export const UiModalBase = AntModal as UiModalBaseType;

const createModalWithUpdate = (modal: Modal) => {
  return {
    ...modal,
    update: (modalFuncUpdateProps: ModalFuncProps) => {
      modal.update(getAntdModalFuncProps(modalFuncUpdateProps));
    },
  };
};

export const useModalBase = () => {
  const { modal: modalBase } = useApp();

  return {
    confirm: (modalFuncProps: ModalConfirmProps): ModalWithCustomUpdateFunc => {
      const { okBtn, cancelBtn } = modalFuncProps;

      const modal = modalBase.confirm(
        getAntdModalFuncProps({
          ...modalFuncProps,
          ...((okBtn || cancelBtn) && {
            footer: <UiModalFooter.Confirm OkBtn={okBtn} CancelBtn={cancelBtn} />,
          }),
          ...(cancelBtn && { closable: true }),
        }),
      );

      return createModalWithUpdate(modal);
    },

    warning: (modalFuncProps: ModalFuncProps): ModalWithCustomUpdateFunc => {
      const modal = modalBase.confirm(getAntdModalWarningFuncProps(modalFuncProps));

      return createModalWithUpdate(modal);
    },

    success: (modalFuncProps: ModalFuncProps): ModalWithCustomUpdateFunc => {
      const modal = modalBase.confirm(getAntdModalSuccessFuncProps(modalFuncProps));

      return createModalWithUpdate(modal);
    },
  };
};
