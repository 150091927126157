// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ca1e3ae3_ui-collapse_show-line .ant-collapse-item .ant-collapse-content{margin-left:10px;margin-top:8px;padding:0 24px;border-left:1px dashed #d5d8de}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/collapse/UiCollapse.scss","webpack://./../../libs/ui/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AAKQ,yEACE,gBAAA,CACA,cCWA,CDVA,cAAA,CACA,8BCIK","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ui-collapse {\n  &_show-line {\n    :global {\n      .ant-collapse-item {\n        .ant-collapse-content {\n          margin-left: calc($iconSize / 2);\n          margin-top: $spaceXS;\n          padding: 0 $spaceXL;\n          border-left: $dashedBorder;\n        }\n      }\n    }\n  }\n}\n","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$baseIconSize: get('themeSizes.baseIconSize');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$borderRadius: 8px; // TODO: вынести в тему\n$borderRadiusHalf: 4px; // TODO: вынести в тему\n$borderRadiusQuarter: 2px; // TODO: вынести в тему\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: getNumberToPx(get('spaceSizes.space2XS'));\n\n$space2XS: getNumberToPx(get('spaceSizes.space2XS'));\n$spaceXS: getNumberToPx(get('spaceSizes.spaceXS'));\n$spaceS: getNumberToPx(get('spaceSizes.spaceS'));\n$spaceM: getNumberToPx(get('spaceSizes.spaceM'));\n$spaceL: getNumberToPx(get('spaceSizes.spaceL'));\n$spaceXL: getNumberToPx(get('spaceSizes.spaceXL'));\n$space2XL: getNumberToPx(get('spaceSizes.space2XL'));\n$space3XL: getNumberToPx(get('spaceSizes.space3XL'));\n$space4XL: getNumberToPx(get('spaceSizes.space4XL'));\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-collapse_show-line": "ca1e3ae3_ui-collapse_show-line",
	"uiCollapse_showLine": "ca1e3ae3_ui-collapse_show-line"
};
export default ___CSS_LOADER_EXPORT___;
