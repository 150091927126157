import React, { FC } from 'react';

import { UiSpinner, UiSpinnerProps } from '../../spinner';

export const LayoutLoading: FC<UiSpinnerProps> = (props) => {
  const { spinning = true, ...rest } = props;

  return (
    <UiSpinner spinning={spinning} {...rest}>
      <div style={{ height: '100vh' }} />
    </UiSpinner>
  );
};
