// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aed091d5_ui-table-column-filter{width:300px}.aed091d5_ui-table-column-filter__list{max-height:200px;overflow:auto}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/table/column/filter/UiTableColumnFilter.scss"],"names":[],"mappings":"AACA,iCACE,WAAA,CAEA,uCACE,gBAAA,CACA,aAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ui-table-column-filter {\n  width: 300px;\n\n  &__list {\n    max-height: 200px;\n    overflow: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-table-column-filter": "aed091d5_ui-table-column-filter",
	"uiTableColumnFilter": "aed091d5_ui-table-column-filter",
	"ui-table-column-filter__list": "aed091d5_ui-table-column-filter__list",
	"uiTableColumnFilter__list": "aed091d5_ui-table-column-filter__list"
};
export default ___CSS_LOADER_EXPORT___;
