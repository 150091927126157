import React, { FC } from 'react';

import { UiTypography, UiTypographyTextProps } from '../../typography';

export interface UiDividerDotProps extends UiTypographyTextProps {
  space?: number;
}

export const UiDividerDot: FC<UiDividerDotProps> = (props) => {
  const { space = 4, ...rest } = props;
  const spacing = {
    paddingRight: space,
    paddingLeft: space,
  };

  return (
    <UiTypography.Text style={spacing} {...rest}>
      •
    </UiTypography.Text>
  );
};
