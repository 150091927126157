import { Mention, MentionProps, SuggestionDataItem } from 'react-mentions';

import { RestParametersWithoutFirst } from '../../types/utils/restParametersWithoutFirst';

type RenderSuggestionRequired = Required<Pick<MentionProps, 'renderSuggestion'>>['renderSuggestion'];
type RenderSuggestionReturn = ReturnType<RenderSuggestionRequired>;
interface RenderSuggestionDataItem<SugData = unknown> extends SuggestionDataItem {
  data?: SugData;
}

export type UiMentionRenderSuggestion<SugData = unknown> = {
  (
    suggestion: RenderSuggestionDataItem<SugData>,
    ...args: RestParametersWithoutFirst<Parameters<RenderSuggestionRequired>>
  ): RenderSuggestionReturn;
};

export type RenderSuggestionDataFunc<SugData = unknown> = (
  query: string,
  callback: (data: RenderSuggestionDataItem<SugData>[]) => void,
) =>
  | Promise<void>
  | void
  | Promise<RenderSuggestionDataItem<SugData>[]>
  | RenderSuggestionDataItem<SugData>[];

export interface UiMentionProps<SugData = unknown> extends Omit<MentionProps, 'renderSuggestion' | 'data'> {
  renderSuggestion?: UiMentionRenderSuggestion<SugData>;
  data: RenderSuggestionDataItem<SugData>[] | RenderSuggestionDataFunc<SugData>;
}

export const UiMention = Mention;
