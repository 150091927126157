import React, { FC, useCallback } from 'react';

import EmojiDisappointedFaceSvg from '@vkph/ui/svg/emoji-disappointed-face.svg';
import EmojiFrowningFaceSvg from '@vkph/ui/svg/emoji-frowning-face.svg';
import EmojiHeartEyesSvg from '@vkph/ui/svg/emoji-heart-eyes.svg';
import EmojiNeutralFaceSvg from '@vkph/ui/svg/emoji-neutral-face.svg';
import EmojiSmilingFaceSvg from '@vkph/ui/svg/emoji-smiling-face.svg';

import { UiCheckbox, UiCheckboxChangeEvent } from '../../checkbox';
import { UiIcon } from '../../icon';
import styles from './UiRateEmoji.scss';

const customIcons = [
  EmojiDisappointedFaceSvg,
  EmojiFrowningFaceSvg,
  EmojiNeutralFaceSvg,
  EmojiSmilingFaceSvg,
  EmojiHeartEyesSvg,
];

export type UiRateEmojiCheckedValue = number | undefined;
export type UiRateEmojiProps = {
  value?: UiRateEmojiCheckedValue;
  onChange?: (value: UiRateEmojiCheckedValue) => void;
  disabled?: boolean;
};

export const UiRateEmoji: FC<UiRateEmojiProps> = (props) => {
  const { onChange, value, disabled } = props;

  const changeHandler = useCallback(
    (event: UiCheckboxChangeEvent) => {
      const newValue = event.target.value;

      onChange?.(value !== newValue ? newValue : undefined);
    },
    [value, onChange],
  );

  return (
    <div className={styles.uiEmoji}>
      {customIcons.map((icon, i) => {
        const key = `icon-${i}`;

        return (
          <UiCheckbox
            disabled={disabled}
            key={key}
            value={i + 1}
            checked={i + 1 === value}
            onChange={changeHandler}
            className={styles.uiEmoji__radioButton}
          >
            <UiIcon className={styles.uiEmoji__icon} width={32} height={32} component={icon} />
          </UiCheckbox>
        );
      })}
    </div>
  );
};
