import { Tag, TagProps } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';

import { variables } from '../../providers/theme';
import { UiCol, UiRow } from '../grid';
import { UiTruncateMarkup } from '../truncate-markup';
import { UiTypography } from '../typography';
import styles from './UiTag.scss';
import { UiTagGroup } from './group/UiTagGroup';

export type { UiTagGroupProps } from './group/UiTagGroup';

export enum UiTagTypes {
  Marker = 'marker',
  Dot = 'dot',
}

export type TagCommonColor = 'blue' | 'orange' | 'success' | 'negative';

export interface UiTagProps extends TagProps {
  title?: string;
  color?: TagCommonColor | string;
  textColor?: string;
  wrapperClassName?: string;
  shift?: number;
  size?: number;
  type?: UiTagTypes;
  strong?: boolean;
  dotClassName?: string;
  rhomb?: boolean;
}

export const UI_TAG_COLOR_MAP: Record<TagCommonColor, string> = {
  blue: variables.themePalette.colorAccidentBlue2,
  orange: variables.themePalette.colorAccidentOrange,
  success: variables.themePalette.colorSuccess,
  negative: variables.themePalette.colorNegative,
};

export interface UiTagComponent extends FC<UiTagProps> {
  Group: typeof UiTagGroup;
}

export const UiTag: UiTagComponent = (props) => {
  const {
    strong,
    className,
    wrapperClassName,
    color,
    textColor,
    title = 'Без категории',
    shift = 8,
    size = 10,
    type = UiTagTypes.Marker,
    children,
    dotClassName,
    rhomb,
    style,
    onClick,
    ...rest
  } = props;

  const tagColor = UI_TAG_COLOR_MAP?.[color as TagCommonColor] || color;

  const TagWithDot = (
    <div className={classNames(styles.uiTag, wrapperClassName)} style={style} {...rest}>
      <div
        style={{ backgroundColor: tagColor, marginRight: shift, width: size, height: size }}
        className={classNames(dotClassName ?? styles.uiTag__dot, {
          [styles.uiTag__dot_rhomb]: rhomb,
        })}
      />
      {children ?? (
        <UiTypography.Text className={classNames({ [styles.uiTag__dot_strong]: strong }, className)}>
          <UiTruncateMarkup contentStyle={{ display: 'inherit', color: textColor }} truncate>
            {title}
          </UiTruncateMarkup>
        </UiTypography.Text>
      )}
    </div>
  );

  const TagMarker = (
    <Tag
      onClick={onClick}
      color={tagColor}
      className={classNames(
        styles.uiTag__marker,
        { [styles.uiTag__marker_strong]: strong },
        { [styles.uiTag__marker_clickable]: onClick },
      )}
      style={{ color: textColor, ...style }}
      {...rest}
    >
      <UiRow align="middle" wrap={false}>
        <UiCol>
          <UiTruncateMarkup tooltipProps={{ title }} truncate>
            {title}
          </UiTruncateMarkup>
        </UiCol>
        <UiCol>{children}</UiCol>
      </UiRow>
    </Tag>
  );

  return type === UiTagTypes.Dot ? TagWithDot : TagMarker;
};

UiTag.Group = UiTagGroup;
