import classNames from 'classnames';
import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState, CSSProperties, FC } from 'react';

import { UiModalStyleType } from '../..';
import styles from './UiModalContent.scss';

export type UiModalContentSteadyMainProps = {
  className?: string;
  children?: ReactNode;
};

export type UiModalContentProps = {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  onHeightChange?: (height: number) => void;
  minHeight?: number;
  type?: UiModalStyleType;
  basePadding?: boolean;
};

type ModalContentSteadyMainComponent = FC<UiModalContentSteadyMainProps>;
type ContentComposition = {
  SteadyMain: ModalContentSteadyMainComponent;
};

export type UiModalContentComponent = FC<UiModalContentProps> & ContentComposition;

export const UiModalContent: UiModalContentComponent = (props) => {
  const {
    children,
    minHeight,
    onHeightChange,
    className,
    style,
    type = UiModalStyleType.Default,
    basePadding,
  } = props;

  const contentWrapperRef = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState<number | undefined>(minHeight || 0);

  useEffect(() => {
    if (onHeightChange && height) {
      onHeightChange(height);
    }
  }, [height]);

  useLayoutEffect(() => {
    const updateHeight = () => {
      setHeight(contentWrapperRef.current?.clientHeight);
    };

    if (contentWrapperRef.current && onHeightChange) {
      window.addEventListener('resize', updateHeight);

      updateHeight();
    }

    return () => window.removeEventListener('resize', updateHeight);
  }, [contentWrapperRef.current]);

  const modalContentClassName = classNames(
    styles.uiModalContent,
    {
      [styles.uiModalContent_primary]: type === UiModalStyleType.Primary,
      [styles.uiModalContent_centered]: type === UiModalStyleType.Centered,
      [styles.uiModalContent_basePadding]: basePadding,
    },
    className,
  );

  return (
    <div ref={contentWrapperRef} className={modalContentClassName} style={{ minHeight, ...style }}>
      {children}
    </div>
  );
};

const UiModalContentSteadyMain: ModalContentSteadyMainComponent = (props) => {
  const { children, className } = props;
  const mainClassname = classNames(styles.uiModalContent__steadyMain, className);

  return <div className={mainClassname}>{children}</div>;
};

UiModalContent.SteadyMain = UiModalContentSteadyMain;
