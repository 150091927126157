import React, { FC, PropsWithChildren } from 'react';

import { useBreakpoint } from '../Layout';

export const LayoutBreadcrumb: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const breakpoints = useBreakpoint();

  if (breakpoints.lg) {
    return <>{children}</>;
  }

  return null;
};
