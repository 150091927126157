import classNames from 'classnames';
import React, { FC } from 'react';

import { AbsolutePlacement } from '../../hooks';
import { UiButtonDecorator } from '../button';
import { UiCheckbox } from '../checkbox';
import { UiImage, UiImageProps } from '../image';
import { UiRadio } from '../radio';
import styles from './UiImageOption.scss';

export interface UiImageOptionProps extends UiImageProps {
  textClassName?: string;
  checked?: boolean;
  value?: number;
  onSelect?: () => void;
  type?: 'radio' | 'checkbox';
  text?: string;
}

export const UiImageOption: FC<UiImageOptionProps> = (props) => {
  const {
    src,
    wrapperClassName,
    checked,
    onSelect,
    type = 'checkbox',
    value,
    className,
    children,
    width = 100,
    height = 100,
  } = props;
  const ChoiceComponent = type === 'radio' ? UiRadio : UiCheckbox;

  const propsWithValue = value ? { value } : {};

  return (
    <UiButtonDecorator
      onClick={onSelect}
      style={{ height, width }}
      className={classNames(styles.uiImageOption, wrapperClassName)}
    >
      <ChoiceComponent
        placement={AbsolutePlacement.TopRight}
        checked={checked}
        {...propsWithValue}
        onChange={onSelect}
        className={styles.uiImageOption__choice}
      >
        <div className={styles.uiImageOption__childrenWrapper}>{children}</div>
      </ChoiceComponent>
      <UiImage
        width={width}
        height={height}
        alt="cover"
        src={src}
        className={classNames(styles.uiImageOption__img, className)}
      />
    </UiButtonDecorator>
  );
};
