import { Avatar, Badge } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';

import AvatarUserSvg from '@vkph/ui/svg/avatar-user.svg';

import { UiAvatarProps, UiAvatarSize } from '.';
import { getSizeClsBySizeFullName } from '../config-provider';
import styles from './UiAvatar.scss';

const ccn = classNames.bind(styles);

export const UiAvatar: FC<UiAvatarProps> = (props) => {
  const {
    icon,
    size: propSize,
    className,
    withBadge = false,
    bordered = false,
    shape = 'circle',
    disabled,
    ...avatarProps
  } = props;

  const size = withBadge ? 64 : propSize;
  const Icon = icon || <AvatarUserSvg height={size} width={size} />;
  const sizeCls = getSizeClsBySizeFullName(
    (propSize && (propSize >= UiAvatarSize.XL ? 'large' : 'small')) || 'middle',
  );

  const classNameStyles = {
    [styles.uiAvatar__circle]: shape === 'circle',
    [styles.uiAvatar__square]: shape === 'square',
    [styles.uiAvatar_disabled]: disabled,
    [`${styles.uiAvatar_bordered}-${sizeCls}`]: bordered && sizeCls,
  };

  const AvatarComponent = (
    <Avatar
      style={{ minWidth: size, minHeight: size }}
      {...avatarProps}
      shape={shape}
      size={size}
      icon={Icon}
      className={ccn(styles.uiAvatar, classNameStyles, className)}
    />
  );

  return withBadge ? <Badge dot>{AvatarComponent}</Badge> : AvatarComponent;
};
