// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1f37643c_ui-dropdown__dropdown-button .ant-btn:first-child:not(:last-child){margin-inline-end:0}._1f37643c_ui-dropdown__dropdown-button .ant-btn:last-child:not(:first-child){border-left:1px solid var(--default-themeVariables-colorBrandFaded);padding:0 16px}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/dropdown/UiDropdown.scss"],"names":[],"mappings":"AAIM,8EACE,mBAAA,CAGF,8EACE,mEAAA,CACA,cAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ui-dropdown {\n  &__dropdown-button {\n    :global(.ant-btn) {\n      &:first-child:not(:last-child) {\n        margin-inline-end: 0;\n      }\n\n      &:last-child:not(:first-child) {\n        border-left: 1px solid $colorBrandFaded;\n        padding: 0 $spaceM;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-dropdown__dropdown-button": "_1f37643c_ui-dropdown__dropdown-button",
	"uiDropdown__dropdownButton": "_1f37643c_ui-dropdown__dropdown-button"
};
export default ___CSS_LOADER_EXPORT___;
