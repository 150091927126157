import React, { FC } from 'react';

export enum UiTypographySymbolName {
  /** Длинное тире */
  EmDash,
  /** Пробел */
  NBSP,
  /** Точка */
  Bullet,
}

/** @link https://www.w3.org/wiki/Common_HTML_entities_used_for_typography */
const UiTypographySymbolType: Record<UiTypographySymbolName, JSX.Element> = {
  [UiTypographySymbolName.EmDash]: <>&mdash;</>,
  [UiTypographySymbolName.NBSP]: <>&nbsp;</>,
  [UiTypographySymbolName.Bullet]: <>&bull;</>,
} as const;

export interface UiTypographySymbolProps {
  name: UiTypographySymbolName;
}

export const UiTypographySymbol: FC<UiTypographySymbolProps> = (props) => {
  const { name } = props;

  return <>{UiTypographySymbolType[name]}</>;
};
