import { Breadcrumb, BreadcrumbProps } from 'antd';
import { AnyObject } from 'antd/lib/_util/type';
import { ItemType } from 'antd/lib/breadcrumb/Breadcrumb';
import classNames from 'classnames';
import React, { FC, ReactNode, useContext } from 'react';

import DropRightSvg from '@vkph/ui/svg/drop-right.svg';

import { UiConfigContext } from '../config-provider';
import { UiTruncateMarkup } from '../truncate-markup';
import styles from './UiBreadcrumb.scss';

export type UiBreadcrumbItemType = ItemType;
export interface UiBreadcrumbProps<T extends AnyObject = AnyObject> extends BreadcrumbProps<T> {
  after?: ReactNode;
  before?: ReactNode;
}

export const UiBreadcrumb: FC<UiBreadcrumbProps> = (props) => {
  const {
    after,
    before,
    className,
    style,
    prefixCls: customizePrefixCls,
    itemRender: customizeItemRender,
    ...breadcrumbProps
  } = props;

  const { getPrefixCls } = useContext(UiConfigContext);
  const prefixCls = getPrefixCls('breadcrumb', customizePrefixCls);

  const itemRender: UiBreadcrumbProps['itemRender'] = (currentRoute, _, items) => {
    const { title, href = '', path, className: itemClassName } = currentRoute;
    const isEmpty = !path;
    const isLast = path && path === items[items.length - 1]?.path;

    if (isLast || isEmpty) {
      return (
        <UiTruncateMarkup truncate lines={1} className={itemClassName}>
          {title}
        </UiTruncateMarkup>
      );
    }

    return (
      <a href={`${href}${path}`} className={classNames(`${prefixCls}-link`, itemClassName)}>
        {title}
      </a>
    );
  };

  return (
    <div className={styles.uiBreadcrumb__wrapper} style={style}>
      {before && <div className={styles.uiBreadcrumb__before}>{before}</div>}
      <Breadcrumb
        itemRender={customizeItemRender || itemRender}
        separator={<DropRightSvg />}
        className={classNames(styles.uiBreadcrumb, className)}
        {...breadcrumbProps}
      />
      {after && <div className={styles.uiBreadcrumb__after}>{after}</div>}
    </div>
  );
};
