import { List } from 'antd';
import { ListItemProps, ListItemMetaProps } from 'antd/lib/list';
import classNames from 'classnames';
import React, { CSSProperties, FC } from 'react';

import styles from './UiListItem.scss';

export interface UiListItemProps extends ListItemProps {
  noStyle?: boolean;
  hoverable?: boolean;
}
export type UiListItemMetaComponent = FC<ListItemMetaProps>;
export type UiListComposition = {
  Meta: UiListItemMetaComponent;
};
export type UiListItemComponent = FC<UiListItemProps> & UiListComposition;

export const UiListItem: UiListItemComponent = (props) => {
  const { onClick, className, style, noStyle, hoverable, ...restProps } = props;
  const itemClassnames = classNames(className, {
    [styles.uiListItem_clickable]: Boolean(onClick),
    [styles.uiListItem_hoverable]: Boolean(onClick) || hoverable,
  });

  const mergedStyle: CSSProperties = {
    ...style,
    all: noStyle ? 'unset' : undefined,
  };

  return <List.Item onClick={onClick} className={itemClassnames} style={mergedStyle} {...restProps} />;
};

UiListItem.Meta = List.Item.Meta;
