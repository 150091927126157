import { createContext, useContext } from 'react';

import { variables } from '../../../providers/theme';

export type LayoutContextValue = {
  outerGutter: string;
  innerGutter: string;
  hasHeader: boolean;
  hasNavbar: boolean;
};

const CONTEXT_DEFAULT_VALUE: LayoutContextValue = {
  outerGutter: variables.layoutSizes.outerGutterLG,
  innerGutter: variables.layoutSizes.innerGutterLG,
  hasHeader: true,
  hasNavbar: true,
};

export const LayoutContext = createContext<LayoutContextValue>(CONTEXT_DEFAULT_VALUE);

export const useLayout = () => {
  return useContext(LayoutContext);
};
