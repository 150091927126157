import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useContext } from 'react';

import { UiConfigProviderSizeContext, getSizeClsBySizeFullName } from '../components/config-provider';

export const useGetSizeClassname = (customizeSize?: SizeType) => {
  const size = useContext(UiConfigProviderSizeContext);

  return getSizeClsBySizeFullName(customizeSize || size || 'middle');
};
