import { Tooltip as AntTooltip } from 'antd';
import { TooltipPlacement, TooltipPropsWithTitle } from 'antd/es/tooltip';
import React, { FC } from 'react';

export type UiTooltipPlacementProps = TooltipPlacement;

export enum UiTooltipPlacement {
  Bottom = 'bottom',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight',
  Left = 'left',
  LeftBottom = 'leftBottom',
  LeftTop = 'leftTop',
  Right = 'right',
  RightBottom = 'rightBottom',
  RightTop = 'rightTop',
  Top = 'top',
  TopLeft = 'topLeft',
  TopRight = 'topRight',
}

const getAlignTargetOffset = (placement: UiTooltipPlacementProps = UiTooltipPlacement.Top) => {
  const shift = 2;
  const mapTargetOffset: Record<UiTooltipPlacement, [number, number]> = {
    [UiTooltipPlacement.Bottom]: [0, shift],
    [UiTooltipPlacement.BottomLeft]: [0, shift],
    [UiTooltipPlacement.BottomRight]: [0, shift],
    [UiTooltipPlacement.Left]: [-shift, 0],
    [UiTooltipPlacement.LeftBottom]: [-shift, 0],
    [UiTooltipPlacement.LeftTop]: [-shift, 0],
    [UiTooltipPlacement.Right]: [shift, 0],
    [UiTooltipPlacement.RightBottom]: [shift, 0],
    [UiTooltipPlacement.RightTop]: [shift, 0],
    [UiTooltipPlacement.Top]: [0, -shift],
    [UiTooltipPlacement.TopLeft]: [0, -shift],
    [UiTooltipPlacement.TopRight]: [0, -shift],
  };

  return mapTargetOffset[placement];
};

export interface UiTooltipProps extends TooltipPropsWithTitle {
  placement?: UiTooltipPlacementProps;
}

export const UiTooltip: FC<UiTooltipProps> = (props) => {
  const { destroyTooltipOnHide = { keepParent: false }, placement } = props;
  const align = { targetOffset: getAlignTargetOffset(placement) };

  return (
    <AntTooltip align={align} mouseEnterDelay={0.3} destroyTooltipOnHide={destroyTooltipOnHide} {...props} />
  );
};
