// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4686c503_ui-icon-circle[class]{border-radius:50%;display:flex;align-items:center;justify-content:center}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/icon/circle/UiIconCircle.scss"],"names":[],"mappings":"AACA,iCACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ui-icon-circle[class] {\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-icon-circle": "_4686c503_ui-icon-circle",
	"uiIconCircle": "_4686c503_ui-icon-circle"
};
export default ___CSS_LOADER_EXPORT___;
