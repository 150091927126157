import classNames from 'classnames';
import React, { FC } from 'react';

import ArrowBackSvg from '../../../../svg/arrow-back.svg';
import { UiButton } from '../../../button';
import { UiIcon } from '../../../icon';
import { UiTooltip } from '../../../tooltip';
import styles from './UiCardHeaderBack.scss';

export type UiCardHeaderBackProps = {
  onClick: React.MouseEventHandler<HTMLElement>;
  tooltipTitle?: string;
  className?: string;
};

export const UiCardHeaderBack: FC<UiCardHeaderBackProps> = (props) => {
  const { onClick, tooltipTitle = 'Назад', className } = props;

  return (
    <UiTooltip className={classNames(className, styles.uiCardHeaderBack)} title={tooltipTitle}>
      <UiButton type="link" onClick={onClick}>
        <UiIcon width={20} height={20} component={ArrowBackSvg} />
      </UiButton>
    </UiTooltip>
  );
};
