// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cc5c1a88_empty-feed-text{display:flex;flex-direction:column;align-items:center;padding:24px}.cc5c1a88_empty-feed-text__typography{max-width:430px;text-align:center}.cc5c1a88_empty-feed-text__header{margin:20px 0 8px}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/empty/feed/message/UiEmptyFeedMessage.scss","webpack://./../../libs/ui/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AACA,0BACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,YCHY,CDKZ,sCACE,eAAA,CACA,iBAAA,CAGF,kCACE,iBAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.empty-feed-text {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: $baseSpacing;\n\n  &__typography {\n    max-width: 430px;\n    text-align: center;\n  }\n\n  &__header {\n    margin: 20px 0 8px;\n  }\n}\n","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$baseIconSize: get('themeSizes.baseIconSize');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$borderRadius: 8px; // TODO: вынести в тему\n$borderRadiusHalf: 4px; // TODO: вынести в тему\n$borderRadiusQuarter: 2px; // TODO: вынести в тему\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: getNumberToPx(get('spaceSizes.space2XS'));\n\n$space2XS: getNumberToPx(get('spaceSizes.space2XS'));\n$spaceXS: getNumberToPx(get('spaceSizes.spaceXS'));\n$spaceS: getNumberToPx(get('spaceSizes.spaceS'));\n$spaceM: getNumberToPx(get('spaceSizes.spaceM'));\n$spaceL: getNumberToPx(get('spaceSizes.spaceL'));\n$spaceXL: getNumberToPx(get('spaceSizes.spaceXL'));\n$space2XL: getNumberToPx(get('spaceSizes.space2XL'));\n$space3XL: getNumberToPx(get('spaceSizes.space3XL'));\n$space4XL: getNumberToPx(get('spaceSizes.space4XL'));\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty-feed-text": "cc5c1a88_empty-feed-text",
	"emptyFeedText": "cc5c1a88_empty-feed-text",
	"empty-feed-text__typography": "cc5c1a88_empty-feed-text__typography",
	"emptyFeedText__typography": "cc5c1a88_empty-feed-text__typography",
	"empty-feed-text__header": "cc5c1a88_empty-feed-text__header",
	"emptyFeedText__header": "cc5c1a88_empty-feed-text__header"
};
export default ___CSS_LOADER_EXPORT___;
