import { ConfigProvider, ConfigProviderProps } from 'antd';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import SizeContext from 'antd/es/config-provider/SizeContext';
import locale from 'antd/locale/ru_RU';
import React, { FC } from 'react';

import { UiEmpty } from '../empty';

export { ConfigContext as UiConfigContext } from 'antd/lib/config-provider';

export const UiConfigProviderSizeContext = SizeContext;
export type UiConfigProviderSizeType = SizeType;

export type UiConfigProviderProps = ConfigProviderProps;

const defaultConfig: UiConfigProviderProps = {
  renderEmpty: () => <UiEmpty />,
  locale,
};

export const UiConfigProvider: FC<UiConfigProviderProps> = (props) => {
  const { children } = props;

  return (
    <ConfigProvider {...defaultConfig} {...props}>
      {children}
    </ConfigProvider>
  );
};

export type SizeClsPostfix = 'lg' | 'sm' | undefined;
export type SizeGutterPostfix = 'lg' | 'sm' | 'md';

export const sizeClassNameMap: Record<Exclude<UiConfigProviderSizeType, undefined>, SizeClsPostfix> = {
  large: 'lg',
  small: 'sm',
  middle: undefined,
};
export const sizeGutterNameMap: Record<Exclude<UiConfigProviderSizeType, undefined>, SizeGutterPostfix> = {
  large: 'lg',
  small: 'sm',
  middle: 'md',
};

export const getSizeClsBySizeFullName = (
  sizeFullname: Exclude<UiConfigProviderSizeType, undefined>,
): string => {
  if (!sizeFullname) {
    return '';
  }

  return sizeClassNameMap[sizeFullname] || '';
};

export const getSizeGutterBySizeFullName = (sizeFullname: UiConfigProviderSizeType): SizeGutterPostfix => {
  if (!sizeFullname || !sizeGutterNameMap[sizeFullname]) {
    return 'md';
  }

  return sizeGutterNameMap[sizeFullname];
};
