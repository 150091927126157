import { Layout } from 'antd';
import React, { CSSProperties, FC, HTMLAttributes, PropsWithChildren } from 'react';

import { variables } from '../../../providers/theme';

interface Props extends HTMLAttributes<HTMLDivElement> {
  stretch?: boolean;
}

export const LayoutContent: FC<PropsWithChildren<Props>> = (props) => {
  const { children, stretch, style, ...rest } = props;

  const mergedStyle: CSSProperties = {
    maxWidth: stretch ? '100%' : variables.layoutSizes.contentMaxWidth,
    ...style,
  };

  return (
    <Layout.Content style={mergedStyle} {...rest}>
      {children}
    </Layout.Content>
  );
};
