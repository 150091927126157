import { PopoverProps } from 'antd/es/popover';
import React, { FC } from 'react';

import { useSpace } from '../../../hooks';
import { UiButton, UiButtonProps } from '../../button';
import { UiRow } from '../../grid';
import { UiSpace } from '../../space';

export interface UiPopoverContentProps extends PopoverProps {
  actions?: Array<UiButtonProps>;
}

export const UiPopoverContent: FC<UiPopoverContentProps> = (props) => {
  const { content, actions } = props;
  const { spaceS, spaceL } = useSpace();

  return (
    <>
      <UiRow>{typeof content === 'function' ? content() : content}</UiRow>
      {actions && (
        <UiRow style={{ paddingTop: spaceL, paddingBottom: spaceS }}>
          <UiSpace>
            {actions.map((action) => (
              <UiButton key={action.label} {...action} />
            ))}
          </UiSpace>
        </UiRow>
      )}
    </>
  );
};
