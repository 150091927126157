import classNames from 'classnames';
import React from 'react';

import ClearSVG from '@vkph/ui/svg/clear.svg';

import { BaseDataType, OptionModel } from '../../../types/option';
import { UiButton } from '../../button';
import styles from './UiMultiSelectItem.scss';

export interface UiMultiSelectItemProps<
  ValueType extends string | number = string,
  DataType extends BaseDataType = BaseDataType,
> extends OptionModel<ValueType, DataType> {
  isDisabled?: boolean;
  onRemove?: (value: ValueType) => void;
  ItemContent?: (props: Omit<UiMultiSelectItemProps<ValueType, DataType>, 'ItemContent'>) => JSX.Element;
  isTransparent?: boolean;
  isDisableRemove?: boolean;
}

export const UiMultiSelectItem = <
  ValueType extends string | number = string,
  DataType extends BaseDataType = BaseDataType,
>(
  props: UiMultiSelectItemProps<ValueType, DataType>,
) => {
  const { value, data, onRemove, isDisabled, ItemContent, isTransparent, isDisableRemove } = props;

  const content = (
    <>
      {ItemContent && <ItemContent {...props} />}
      {!ItemContent && (
        <span
          className={classNames(styles.uiMultiSelectItem__label, {
            [styles.uiMultiSelectItem__label_transparent]: isTransparent,
          })}
        >
          {data.selectedLabel}
        </span>
      )}
    </>
  );

  return (
    <div
      key={value}
      className={classNames(styles.uiMultiSelectItem, {
        [styles.uiMultiSelectItem_transparent]: isTransparent,
      })}
    >
      {content}
      {onRemove && !isDisableRemove && (
        <UiButton
          type="link"
          onClick={() => onRemove(value)}
          className={styles.uiMultiSelectItem__closeIcon}
          icon={<ClearSVG />}
          disabled={isDisabled}
          size="middle"
        />
      )}
    </div>
  );
};
