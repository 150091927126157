import { Popover as AntPopover } from 'antd';
import { PopoverProps } from 'antd/es/popover';
import classNames from 'classnames';
import React, { FC } from 'react';

import { UiButtonProps } from '../button';
import styles from './UiPopover.scss';
import { UiPopoverContent } from './content/UiPopoverContent';

export interface UiPopoverProps extends PopoverProps {
  actions?: Array<UiButtonProps>;
}

export const UiPopover: FC<UiPopoverProps> = (props) => {
  const { children, content, actions, className, ...restProps } = props;

  return (
    <AntPopover
      className={classNames(className, styles.uiPopover)}
      content={<UiPopoverContent content={content} actions={actions} />}
      {...restProps}
    >
      {children}
    </AntPopover>
  );
};
