type GetSplitFileNameExtension = {
  name: string;
  extension: string;
};

export const getSplitFileNameExtension = (fileName: string = ''): GetSplitFileNameExtension => {
  let result = {
    name: fileName,
    extension: '',
  };

  if (fileName.includes('.')) {
    const name = fileName.substring(0, fileName.lastIndexOf('.'));
    const splitByDot = fileName.split('.');
    const extension = splitByDot[splitByDot.length - 1];

    result = {
      name,
      extension,
    };
  }

  return result;
};
