import React, { FC, PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

import { objectEntriesTyped, objectFromEntriesTyped } from '../../utils';
import { variables } from '../theme';
import { SpacesContextValue, SpacesType, useSpaceProps } from './types';

export const SpaceContext = createContext<SpacesContextValue>([variables.spaceSizes]);

export const SpaceProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const [spaces, setSpaces] = useState<SpacesType>(variables.spaceSizes);

  const contextValue = useMemo<SpacesContextValue>(() => [spaces, setSpaces], [spaces]);

  return <SpaceContext.Provider value={contextValue}>{children}</SpaceContext.Provider>;
};

export function useSpace(): SpacesType<number>;
export function useSpace(config: useSpaceProps): SpacesType<string>;

export function useSpace(config?: useSpaceProps): SpacesType<string> | SpacesType<number> {
  const [spaces] = useContext(SpaceContext);

  return useMemo(() => {
    if (config) {
      const { unit } = config;

      return objectFromEntriesTyped(
        objectEntriesTyped(spaces).map(([key, value]) => [key, `${value}${unit}`]),
      );
    }

    return spaces;
  }, [spaces]);
}
