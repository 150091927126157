import { UiButton, UiRow, UiIcon } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import BurgerIcon from '@vkph/ui/svg/burger.svg';

import { useTheme, variables } from '../../../../providers/theme';

type Props = {
  containerClassName: string;
  toggleNavbar: () => void;
};

export const LayoutNavbarCollapsed: FC<PropsWithChildren<Props>> = (props) => {
  const { children, containerClassName, toggleNavbar } = props;
  const [{ variables: themeVariables }] = useTheme();

  return (
    <>
      <div style={{ width: variables.layoutSizes.navbarCollapsedWidth }} className={containerClassName}>
        <UiRow style={{ padding: '22px 0' }} justify="center" align="middle">
          <UiButton
            type="link"
            size="large"
            icon={<UiIcon component={BurgerIcon} width={20} height={20} color={themeVariables.colorBrand} />}
            onClick={toggleNavbar}
          />
        </UiRow>
      </div>
      {children}
    </>
  );
};
