import generatePicker from 'antd/es/date-picker/generatePicker';
import rusLocale from 'antd/lib/date-picker/locale/ru_RU';
import classNames from 'classnames';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import React, { FC } from 'react';

import ArrowFrontSVG from '@vkph/ui/svg/arrow-front.svg';
import CalendarSvg from '@vkph/ui/svg/calendar.svg';
import ClearSVG from '@vkph/ui/svg/clear.svg';

import { UiIcon } from '../../icon';
import { datePickerDataFormat, UiDatePickerDataFormat } from '../UiDatePicker';
import styles from './UiDatePickerRange.scss';

rusLocale.lang.locale = 'ru';

const DateFnsDatePicker = generatePicker<Date>(dateFnsGenerateConfig).RangePicker;

type BaseProps = typeof DateFnsDatePicker.defaultProps;

export type UiDatePickerRangeProps = {
  hasFullWidth?: boolean;
};

export const UiDatePickerRange: FC<BaseProps & UiDatePickerRangeProps> = (props) => {
  const { className, hasFullWidth = false, ...rest } = props;

  const pickerClassName = classNames(
    styles.uiDatePickerRange,
    {
      [styles.uiDatePickerRange__fullWidth]: hasFullWidth,
    },
    className,
  );

  const SeparatorIcon = (
    <UiIcon
      className={styles.uiDatePickerRange__separatorIcon}
      component={ArrowFrontSVG}
      width={20}
      height={20}
    />
  );

  return (
    <DateFnsDatePicker
      locale={rusLocale}
      format={datePickerDataFormat[UiDatePickerDataFormat.Data]}
      separator={SeparatorIcon}
      suffixIcon={<CalendarSvg />}
      clearIcon={<ClearSVG />}
      className={pickerClassName}
      {...rest}
    />
  );
};
