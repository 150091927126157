import { Dropdown as AntDropdown, DropdownProps as AntDropdownProps } from 'antd';
import { DropdownButtonProps as AntDropdownButtonProps } from 'antd/lib/dropdown';
import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './UiDropdown.scss';

export type UiDropdownProps = AntDropdownProps;
export type UiDropdownButtonProps = AntDropdownButtonProps;

const UiDropdownButton: FC<UiDropdownButtonProps> = (props) => {
  const { className, ...restProps } = props;

  return (
    <AntDropdown.Button
      {...restProps}
      className={classNames(styles.uiDropdown__dropdownButton, className)}
      // @ts-expect-error Переопределил block для Space.Compact, через ...restProps
      block={false}
    />
  );
};

type UiDropdownComposition = {
  Button: typeof UiDropdownButton;
};

export const UiDropdown: FC<UiDropdownProps> & UiDropdownComposition = (props) => <AntDropdown {...props} />;

UiDropdown.Button = UiDropdownButton;
