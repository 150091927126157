import React, { FC, ReactNode, useContext, useMemo } from 'react';

import {
  getSizeGutterBySizeFullName,
  sizeClassNameMap,
  UiConfigProviderSizeContext,
} from '../config-provider';
import { UiCol, UiRow } from '../grid';
import { UiSpace, UiSpaceProps } from '../space';
import { UiSpinner } from '../spinner';
import { UiFileCompact } from './compact';
import { fileGutterSizes } from './constants';
import { iconSizeMap, UiFileIcon, UiFileIconProps, UiFileIconSize } from './icon';

export interface UiFileComposition {
  Icon: typeof UiFileIcon;
  Compact: typeof UiFileCompact;
}

type UiFileAlignSettings = {
  align: UiSpaceProps['align'];
  sizeSpace: number;
  subtitle: ReactNode;
  sizeIcon: UiFileIconSize;
};

export enum UiFileDirection {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export interface UiFileProps extends UiFileIconProps {
  direction?: UiFileDirection;
  title: ReactNode;
  subtitle: ReactNode;
  loading?: boolean;
  thumbnail?: ReactNode;
}

export const UiFile: FC<UiFileProps> & UiFileComposition = (props) => {
  const {
    title,
    subtitle: subtitleProp,
    size,
    thumbnail,
    direction = UiFileDirection.Horizontal,
    loading,
    ...iconProps
  } = props;
  const providerSize = useContext(UiConfigProviderSizeContext);

  const directionSettingsMap = useMemo<Record<string, Partial<UiFileAlignSettings>>>(() => {
    const sizeName = size || providerSize;
    const sizeGutterName = getSizeGutterBySizeFullName(sizeName || 'middle');

    return {
      vertical: {
        sizeSpace: fileGutterSizes.sm,
        subtitle: subtitleProp,
        align: 'center',
      },
      horizontal: {
        sizeIcon: sizeGutterName && iconSizeMap[sizeGutterName],
        sizeSpace: sizeGutterName && fileGutterSizes[sizeGutterName],
        subtitle: sizeGutterName !== sizeClassNameMap.small && subtitleProp,
      },
    };
  }, [providerSize, size, subtitleProp]);

  return (
    <UiSpace
      size={directionSettingsMap[direction].sizeSpace}
      direction={direction}
      align={directionSettingsMap[direction].align}
    >
      <UiSpinner spinning={Boolean(loading)}>
        <UiRow
          style={{
            width: directionSettingsMap[direction].sizeIcon || '100%',
            height: directionSettingsMap[direction].sizeIcon || 100,
          }}
        >
          <UiCol span={24}>
            {thumbnail || (
              <UiFile.Icon
                direction={direction}
                size={size}
                width={directionSettingsMap[direction].sizeIcon || 100}
                height={directionSettingsMap[direction].sizeIcon || 100}
                {...iconProps}
              />
            )}
          </UiCol>
        </UiRow>
      </UiSpinner>
      <UiSpace direction="vertical" size={0} align={directionSettingsMap[direction].align}>
        {title}
        {directionSettingsMap[direction].subtitle}
      </UiSpace>
    </UiSpace>
  );
};

UiFile.Icon = UiFileIcon;
UiFile.Compact = UiFileCompact;
