// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f485e25a_ui-form-section{padding:24px 24px 0}.f485e25a_ui-form-section_bordered-bottom{border-bottom:1px solid var(--default-themeVariables-colorStroke)}.f485e25a_ui-form-section_bordered-top{border-top:1px solid var(--default-themeVariables-colorStroke)}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/form/section/UiFormSection.scss","webpack://./../../libs/ui/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AACA,0BACE,mBAAA,CAGE,0CACE,iECMS,CDHX,uCACE,8DCES","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ui-form-section {\n  padding: $baseSpacing $baseSpacing 0;\n\n  &_bordered {\n    &-bottom {\n      border-bottom: $spacerBorder;\n    }\n\n    &-top {\n      border-top: $spacerBorder;\n    }\n  }\n}\n","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$baseIconSize: get('themeSizes.baseIconSize');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$borderRadius: 8px; // TODO: вынести в тему\n$borderRadiusHalf: 4px; // TODO: вынести в тему\n$borderRadiusQuarter: 2px; // TODO: вынести в тему\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: getNumberToPx(get('spaceSizes.space2XS'));\n\n$space2XS: getNumberToPx(get('spaceSizes.space2XS'));\n$spaceXS: getNumberToPx(get('spaceSizes.spaceXS'));\n$spaceS: getNumberToPx(get('spaceSizes.spaceS'));\n$spaceM: getNumberToPx(get('spaceSizes.spaceM'));\n$spaceL: getNumberToPx(get('spaceSizes.spaceL'));\n$spaceXL: getNumberToPx(get('spaceSizes.spaceXL'));\n$space2XL: getNumberToPx(get('spaceSizes.space2XL'));\n$space3XL: getNumberToPx(get('spaceSizes.space3XL'));\n$space4XL: getNumberToPx(get('spaceSizes.space4XL'));\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-form-section": "f485e25a_ui-form-section",
	"uiFormSection": "f485e25a_ui-form-section",
	"ui-form-section_bordered-bottom": "f485e25a_ui-form-section_bordered-bottom",
	"uiFormSection_borderedBottom": "f485e25a_ui-form-section_bordered-bottom",
	"ui-form-section_bordered-top": "f485e25a_ui-form-section_bordered-top",
	"uiFormSection_borderedTop": "f485e25a_ui-form-section_bordered-top"
};
export default ___CSS_LOADER_EXPORT___;
