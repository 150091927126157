// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f8f9af6a_ui-table-cell-dash{display:flex;justify-content:center}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/table/cell-dash/UiTableCellDash.scss"],"names":[],"mappings":"AACA,6BACE,YAAA,CACA,sBAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ui-table-cell-dash {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-table-cell-dash": "f8f9af6a_ui-table-cell-dash",
	"uiTableCellDash": "f8f9af6a_ui-table-cell-dash"
};
export default ___CSS_LOADER_EXPORT___;
