import { Grid, SiderProps } from 'antd';
import React, { FC } from 'react';

import { UiCol, UiColProps } from '../../grid';

export const LayoutSider: FC<UiColProps & Pick<SiderProps, 'breakpoint'>> = (props) => {
  const { children, breakpoint = 'lg', ...rest } = props;

  const breakpoints = Grid.useBreakpoint();

  return (
    <UiCol {...rest} hidden={!breakpoints[breakpoint]}>
      {children}
    </UiCol>
  );
};
