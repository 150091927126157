import { Rate, RateProps } from 'antd';
import React, { FC } from 'react';

import { UiRateEmoji } from './rate-emoji/UiRateEmoji';
import { UiRateStar } from './rate-star/UiRateStar';

type CropImageAreaComposition = {
  Star: typeof UiRateStar;
  Emoji: typeof UiRateEmoji;
};

export const UiRate: FC<RateProps> & CropImageAreaComposition = (props) => {
  return <Rate {...props} />;
};

UiRate.Star = UiRateStar;
UiRate.Emoji = UiRateEmoji;
