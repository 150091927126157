import { Layout, Grid } from 'antd';
import classNames from 'classnames';
import React, { FC, PropsWithChildren, HTMLAttributes, useCallback, PointerEvent } from 'react';

import BurgerSvg from '@vkph/ui/svg/burger.svg';
import CloseSvg from '@vkph/ui/svg/close.svg';

import { variables } from '../../../providers/theme';
import { UiButton } from '../../button';
import { UiIcon } from '../../icon';
import { LAYOUT_NAVBAR_COLLAPSED_BREAKPOINT } from '../constants';
import { useLayout } from '../hooks';
import styles from './LayoutHeader.scss';

interface Props extends HTMLAttributes<HTMLElement> {
  isNavbarCollapsed: boolean;
  toggleNavbar: () => void;
}

export const LayoutHeader: FC<PropsWithChildren<Props>> = (props) => {
  const { children, className, isNavbarCollapsed, toggleNavbar, ...rest } = props;
  const breakpoints = Grid.useBreakpoint();
  const { hasNavbar } = useLayout();
  const isNavbarCollapsible = !breakpoints[LAYOUT_NAVBAR_COLLAPSED_BREAKPOINT];

  // https://gitlab.corp.mail.ru/people-hub/apps/frontend/-/issues/408
  const handleToggleNavbar = useCallback((event: PointerEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    toggleNavbar();
  }, []);

  return (
    <Layout.Header
      {...rest}
      className={classNames(styles.layoutHeader, styles.layoutHeader_fixed, className)}
    >
      {hasNavbar && isNavbarCollapsible && (
        <UiButton
          type="link"
          size="small"
          round
          icon={
            <UiIcon
              component={isNavbarCollapsed ? BurgerSvg : CloseSvg}
              color={variables.themePalette.white}
              width={20}
              height={20}
            />
          }
          onPointerDown={handleToggleNavbar}
        />
      )}
      {children}
    </Layout.Header>
  );
};
