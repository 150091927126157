// TODO разобраться с непонятными переменнами или удалить эту функцию
export const getColorBasedOnBackgroundColor = (
  bgColor = '',
  lightColor = '#FFFFFF',
  darkColor = '#000000',
) => {
  const isRgb = bgColor.startsWith('rgb');
  const isHex = bgColor.startsWith('#');

  let uiColors: [number, number, number] = [0, 0, 0];

  if (isHex) {
    const r = parseInt(bgColor.substring(1, 3), 16); // hexToR
    const g = parseInt(bgColor.substring(3, 5), 16); // hexToG
    const b = parseInt(bgColor.substring(5, 7), 16); // hexToB

    uiColors = [r / 255, g / 255, b / 255];
  }

  if (isRgb) {
    const [r, g, b] = bgColor.match(/[.\d]+/g) || ['0', '0', '0'];

    uiColors = [Number(r), Number(g), Number(b)];
  }

  const c = uiColors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }

    return ((col + 0.055) / 1.055) ** 2.4;
  });

  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];

  return L > 0.179 ? darkColor : lightColor;
};
