import isObject from 'lodash/isObject';
import { ImagePreviewType as AntImagePreviewType, ImageProps as AntImageProps } from 'rc-image/lib/Image';
import React, { FC, PropsWithChildren, ReactNode, useMemo } from 'react';

import CloseSvg from '@vkph/ui/svg/close.svg';
import DownloadSvg from '@vkph/ui/svg/download.svg';
import RotateLeftSvg from '@vkph/ui/svg/rotate-left.svg';
import RotateSvg from '@vkph/ui/svg/rotate.svg';
import ShareSvg from '@vkph/ui/svg/share.svg';
import ZoomInSvg from '@vkph/ui/svg/zoom-in.svg';
import ZoomOutSvg from '@vkph/ui/svg/zoom-out.svg';

import { UiRow, UiIcon, UiSpace } from '../../..';
import { UiImageProps } from '../UiImage';
import { UiImagePreviewButton } from './button/UiImagePreviewButton';

type UiImagePreviewComposition = {
  Button: typeof UiImagePreviewButton;
};

const SCALE_MIN = 0.2;
const SCALE_MAX = 10;
const SCALE_STEP = 0.2;

export interface UiImagePreviewPropExtended extends AntImagePreviewType {
  toolbarExtra?: ReactNode;
  customize?: {
    onShare?: () => void;
    onDownload?: () => void;
    title?: ReactNode;
    subtitle?: ReactNode;
  };
}

interface UiImagePreviewProps extends Pick<UiImageProps, 'wrapperStyle'> {
  preview: UiImagePreviewPropExtended | true;
}
type UiImagePreviewComponent = FC<PropsWithChildren<UiImagePreviewProps>> & UiImagePreviewComposition;

export const UiImagePreview: UiImagePreviewComponent = (props) => {
  const { preview, children } = props;
  const { customize, ...restPreviewConfig }: UiImagePreviewPropExtended = isObject(preview) ? preview : {};

  const getDefaultToolbar: AntImagePreviewType['toolbarRender'] = (
    _,
    { transform: { scale }, actions: { onRotateLeft, onRotateRight, onZoomOut, onZoomIn } },
  ) => (
    <UiRow justify="space-between" style={{ width: '100%' }}>
      <UiSpace direction="vertical">
        {customize?.title}
        {customize?.subtitle}
      </UiSpace>
      <UiSpace size={48}>
        <UiSpace size={24}>
          {customize?.onDownload && (
            <UiImagePreviewButton icon={DownloadSvg} onClick={customize.onDownload} />
          )}
          {customize?.onShare && <UiImagePreviewButton icon={ShareSvg} onClick={customize.onShare} />}
        </UiSpace>
        <UiSpace size={24}>
          <UiImagePreviewButton icon={RotateLeftSvg} onClick={onRotateLeft} />
          <UiImagePreviewButton icon={RotateSvg} onClick={onRotateRight} />
        </UiSpace>
        <UiSpace size={24}>
          <UiImagePreviewButton icon={ZoomInSvg} onClick={onZoomIn} disabled={scale === SCALE_MAX} />
          <UiImagePreviewButton icon={ZoomOutSvg} disabled={scale === SCALE_MIN} onClick={onZoomOut} />
        </UiSpace>
      </UiSpace>
    </UiRow>
  );

  const previewConfig = useMemo<AntImageProps['preview']>(() => {
    return {
      toolbarRender: getDefaultToolbar,
      mask: <div style={{ cursor: 'pointer' }} />,
      scaleStep: SCALE_STEP,
      minScale: SCALE_MIN,
      maxScale: SCALE_MAX,
      closeIcon: <UiIcon width={20} height={20} component={CloseSvg} />,
      ...restPreviewConfig,
    };
  }, []);

  return (
    <>
      {React.Children.map<ReactNode, ReactNode>(children, (child) => {
        if (React.isValidElement<UiImageProps>(child)) {
          return React.cloneElement(child, { preview: previewConfig });
        }

        return null;
      })}
    </>
  );
};

UiImagePreview.Button = UiImagePreviewButton;
