// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f251b229_ui-space_full{width:100%}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/space/UiSpace.scss"],"names":[],"mappings":"AAEE,wBACE,UAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ui-space {\n  &_full {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-space_full": "f251b229_ui-space_full",
	"uiSpace_full": "f251b229_ui-space_full"
};
export default ___CSS_LOADER_EXPORT___;
