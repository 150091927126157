import { Spin } from 'antd';
import React, { useContext, FC, ComponentProps } from 'react';

import { UiConfigProviderSizeContext } from '../config-provider';
import { UiSpinnerIndicator } from './indicator/UiSpinnerIndicator';

export type UiSpinnerProps = ComponentProps<typeof Spin>;
type UiSpinnerComposition = {
  Indicator: typeof UiSpinnerIndicator;
};

export const UiSpinner: FC<UiSpinnerProps> & UiSpinnerComposition = (props) => {
  const { size: sizeProps } = props;
  const sizeContext = useContext(UiConfigProviderSizeContext);
  const size = sizeContext === 'middle' ? 'default' : sizeContext;

  return <Spin indicator={UiSpinnerIndicator} size={sizeProps || size || 'large'} {...props} />;
};

UiSpinner.Indicator = UiSpinnerIndicator;
