import { Divider, DividerProps } from 'antd';
import classNames from 'classnames';
import React, { CSSProperties, FC } from 'react';

import styles from './UiDivider.scss';
import { UiDividerDot } from './dot/UiDividerDot';

type DividerComposition = {
  Dot: typeof UiDividerDot;
};

export interface UiDividerProps extends DividerProps {
  emptyMargin?: boolean;
  column?: boolean;
  overwriteBasePadding?: boolean;
}

export const UiDivider: FC<UiDividerProps> & DividerComposition = (props) => {
  const { style, emptyMargin, overwriteBasePadding, className, column, ...rest } = props;

  const customStyle: CSSProperties = {
    ...style,
    ...(emptyMargin && { margin: 0 }),
  };

  return (
    <Divider
      style={customStyle}
      className={classNames(className, {
        [styles.uiDivider_column]: column,
        [styles.uiDivider_overwriteBasePadding]: overwriteBasePadding && !emptyMargin,
      })}
      {...rest}
    />
  );
};

UiDivider.Dot = UiDividerDot;
