import classNames from 'classnames';
import { CSSProperties, useMemo } from 'react';

export enum AbsolutePlacement {
  BottomCenter = 'bottomCenter',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight',
  TopCenter = 'topCenter',
  TopLeft = 'topLeft',
  TopRight = 'topRight',
  Center = 'center',
  CenterLeft = 'centerLeft',
  CenterRight = 'centerRight',
}

export type ShiftVariants = Pick<CSSProperties, 'bottom' | 'left' | 'right' | 'top'>;

export type AbstractAbsoluteShiftPlacement = {
  placement?: AbsolutePlacement;
  shift?: number | ShiftVariants;
};

type UsePlacementShiftType = {
  placementCls?: string;
  placementStyles?: CSSProperties;
};

type UseAbsolutePlacementShiftParams = {
  placement?: AbsolutePlacement;
  shift?: AbstractAbsoluteShiftPlacement['shift'];
  position?: 'absolute' | 'fixed';
};

export const useAbsolutePlacementShift = (
  params?: UseAbsolutePlacementShiftParams,
): UsePlacementShiftType => {
  const { placement, shift, position = 'absolute' } = params || {};
  const shiftVariants: ShiftVariants = useMemo(() => {
    const defaultShift = 0;

    if (!shift || typeof shift === 'number') {
      const innerShift = shift || defaultShift;

      return {
        bottom: innerShift,
        left: innerShift,
        right: innerShift,
        top: innerShift,
      };
    }

    return {
      bottom: shift.bottom || defaultShift,
      top: shift.top || defaultShift,
      right: shift.right || defaultShift,
      left: shift.left || defaultShift,
    };
  }, [shift]);

  const mapPlacementShift = useMemo<Record<AbsolutePlacement, CSSProperties>>(() => {
    return {
      [AbsolutePlacement.BottomCenter]: {
        left: '50%',
        transform: 'translate(-50%, 0)',
        bottom: shiftVariants.bottom,
      },
      [AbsolutePlacement.BottomLeft]: {
        left: shiftVariants.left,
        bottom: shiftVariants.bottom,
      },
      [AbsolutePlacement.BottomRight]: {
        right: shiftVariants.right,
        bottom: shiftVariants.bottom,
      },
      [AbsolutePlacement.TopCenter]: {
        left: '50%',
        transform: 'translate(-50%, 0)',
        top: shiftVariants.top,
      },
      [AbsolutePlacement.TopLeft]: {
        top: shiftVariants.top,
        left: shiftVariants.left,
      },
      [AbsolutePlacement.TopRight]: {
        top: shiftVariants.top,
        right: shiftVariants.right,
      },
      [AbsolutePlacement.CenterLeft]: {
        top: '50%',
        transform: 'translate(0, -50%)',
        left: shiftVariants.left,
      },
      [AbsolutePlacement.CenterRight]: {
        top: '50%',
        transform: 'translate(0, -50%)',
        right: shiftVariants.right,
      },
      [AbsolutePlacement.Center]: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    };
  }, [shiftVariants]);

  return {
    placementCls: placement ? classNames('ph-placement', `ph-placement_${position}`) : undefined,
    placementStyles: placement ? mapPlacementShift[placement] : undefined,
  };
};
