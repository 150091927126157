import classnames from 'classnames';
import React, { FC, useMemo, CSSProperties } from 'react';

import FileArchiveSvg from '@vkph/ui/svg/file-archive.svg';
import FileWithExtensionSvg from '@vkph/ui/svg/file-with-extension.svg';
import FolderSvg from '@vkph/ui/svg/folder-files.svg';

import { UiFileProps } from '..';
import { getSplitFileNameExtension } from '../../../utils';
import { getSizeClsBySizeFullName, SizeGutterPostfix, UiConfigProviderSizeType } from '../../config-provider';
import { UiIcon, UiIconProps } from '../../icon';
import {
  DEFAULT_ARCHIVE_EXTENSIONS,
  DEFAULT_FILE_EXTENSION_COLOR,
  fileExtensionColorsMap,
} from '../constants';
import styles from './UiFileIcon.scss';

export enum UiFileIconSize {
  Small = 32,
  Middle = 40,
  Large = 64,
}

export const iconSizeMap: Record<SizeGutterPostfix, UiFileIconSize> = {
  sm: UiFileIconSize.Small,
  md: UiFileIconSize.Middle,
  lg: UiFileIconSize.Large,
};

export interface UiFileIconProps
  extends Pick<UiIconProps, 'width' | 'height'>,
    Pick<UiFileProps, 'direction'> {
  fileName: string;
  icon?: SvgrComponent;
  size?: UiConfigProviderSizeType;
  archiveExtensions?: Set<string>;
  isFolder?: boolean;
}

export const getFileExtensionColor = (lowercaseExtension: string) => {
  return fileExtensionColorsMap[lowercaseExtension] || DEFAULT_FILE_EXTENSION_COLOR;
};

export const UiFileIcon: FC<UiFileIconProps> = (props) => {
  const {
    size,
    direction,
    icon: iconProp,
    fileName,
    archiveExtensions = DEFAULT_ARCHIVE_EXTENSIONS,
    isFolder,
    ...restProps
  } = props;
  const isVertical = direction === 'vertical';

  const sizeCls = getSizeClsBySizeFullName(size || 'middle');
  const { extension } = getSplitFileNameExtension(fileName);
  const extensionStyles = useMemo<CSSProperties>(
    () => (extension ? { backgroundColor: getFileExtensionColor(extension) } : {}),
    [extension],
  );

  const isArchive = archiveExtensions.has(extension);
  const isShowExtension = !isArchive && !isFolder;

  const icon = useMemo(() => {
    if (isFolder) {
      return FolderSvg;
    }

    if (isArchive) {
      return FileArchiveSvg;
    }

    return FileWithExtensionSvg;
  }, [iconProp, isFolder, isArchive]);

  return (
    <>
      <UiIcon component={icon} {...restProps} />
      {isShowExtension && (
        <span
          className={classnames(styles.uiFileIcon__extension, {
            [`${styles.uiFileIcon__extension}_${sizeCls}`]: sizeCls,
            [`${styles.uiFileIcon__extension}_${direction}`]: isVertical,
          })}
          style={extensionStyles}
        >
          {extension}
        </span>
      )}
    </>
  );
};
