import React, { FC } from 'react';

import AttachSVG from '@vkph/ui/svg/attach.svg';

import { UiButton, UiButtonProps } from '../../button';
import { UiIcon } from '../../icon';

export interface UiFileCompactProps extends Pick<UiButtonProps, 'style' | 'label'> {
  url: string;
}

export const UiFileCompact: FC<UiFileCompactProps> = (props) => {
  const { url, label, style } = props;

  return (
    <UiButton
      type="link-secondary"
      href={url}
      target="_blank"
      disabledFocus
      style={{ width: 'fit-content', ...style }}
      label={label}
      icon={<UiIcon component={AttachSVG} height={20} width={20} />}
    />
  );
};
