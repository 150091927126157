import { FilterDropdownProps } from 'antd/es/table/interface';
import React, { FC } from 'react';

import SearchSvg from '@vkph/ui/svg/search.svg';

import { UiIcon } from '../../../icon';
import { UiInput, UiInputProps } from '../../../input';

export interface UiTableColumnSearchProps extends Omit<UiInputProps, 'onChange'> {
  onChange: (value: string) => void;
  filterDropdownProps: FilterDropdownProps;
}

const UiTableColumnSearchIcon = () => <UiIcon component={SearchSvg} width={20} height={14} />;

interface UiTableColumnSearchComposition {
  Icon: typeof UiTableColumnSearchIcon;
}

export const UiTableColumnSearch: FC<UiTableColumnSearchProps> & UiTableColumnSearchComposition = (props) => {
  const { filterDropdownProps, onChange: changeHandler, ...inputProp } = props;
  const { selectedKeys, setSelectedKeys } = filterDropdownProps;

  const onChange: UiInputProps['onChange'] = (e) => {
    const { value } = e.target;

    setSelectedKeys(value ? [value] : []);
    changeHandler?.(value);
  };

  const onClear = () => {
    setSelectedKeys([]);
    changeHandler?.('');
  };

  return (
    <div style={{ padding: 8 }}>
      <UiInput
        {...inputProp}
        onChange={onChange}
        onClear={onClear}
        value={selectedKeys[0]}
        allowClear
        suffix={<span />} // https://4x.ant.design/components/input/#Why-Input-lose-focus-when-change-prefix/suffix/showCount
      />
    </div>
  );
};

UiTableColumnSearch.Icon = UiTableColumnSearchIcon;
