// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._27a6fdb5_ui-table-cell-avatar{display:flex;align-items:center}._27a6fdb5_ui-table-cell-avatar__avatar{margin-right:12px}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/table/cell-avatar/UiTableCellAvatar.scss"],"names":[],"mappings":"AACA,gCACE,YAAA,CACA,kBAAA,CAEA,wCACE,iBAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ui-table-cell-avatar {\n  display: flex;\n  align-items: center;\n\n  &__avatar {\n    margin-right: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-table-cell-avatar": "_27a6fdb5_ui-table-cell-avatar",
	"uiTableCellAvatar": "_27a6fdb5_ui-table-cell-avatar",
	"ui-table-cell-avatar__avatar": "_27a6fdb5_ui-table-cell-avatar__avatar",
	"uiTableCellAvatar__avatar": "_27a6fdb5_ui-table-cell-avatar__avatar"
};
export default ___CSS_LOADER_EXPORT___;
