// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2125b126_truncate-markup{white-space:normal;word-break:break-word;background:rgba(0,0,0,0)}._2125b126_truncate-markup>*{display:inline-block}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/truncate-markup/UiTruncateMarkup.scss"],"names":[],"mappings":"AACA,2BACE,kBAAA,CACA,qBAAA,CACA,wBAAA,CAEA,6BACE,oBAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.truncate-markup {\n  white-space: normal;\n  word-break: break-word;\n  background: transparent;\n\n  & > * {\n    display: inline-block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"truncate-markup": "_2125b126_truncate-markup",
	"truncateMarkup": "_2125b126_truncate-markup"
};
export default ___CSS_LOADER_EXPORT___;
