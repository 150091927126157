import { Pagination as AntPagination } from 'antd';
import { PaginationConfig } from 'antd/es/pagination';
import React, { FC } from 'react';

import { UiPaginationTotal } from './total/UiPaginationTotal';

export type UiPaginationConfig = PaginationConfig;

export type { PaginationProps as UiPaginationProps } from 'antd';

export interface UiPaginationComposition extends FC<UiPaginationConfig> {
  Total: typeof UiPaginationTotal;
}

export const UiPagination: UiPaginationComposition = (props) => <AntPagination {...props} />;

UiPagination.Total = UiPaginationTotal;
