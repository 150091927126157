import classNames from 'classnames';
import React, { FC } from 'react';

import { UiButton } from '../button';
import { UiDivider } from '../divider';
import { UiIcon } from '../icon';
import styles from './UiBadgePanel.scss';

export type UiBadgePanelOption = {
  icon?: SvgrComponent;
  divider?: boolean;
  onClick?: () => void;
};

export type UiBadgePanelProps = {
  className?: string;
  options: UiBadgePanelOption[];
};

export const UiBadgePanel: FC<UiBadgePanelProps> = (props) => {
  const { className, options } = props;

  const renderOption = (option: UiBadgePanelOption, index: number) => {
    return (
      <React.Fragment key={index}>
        <UiButton
          size="small"
          onClick={option?.onClick}
          icon={<UiIcon component={option.icon} width={20} height={20} />}
        />
        {option.divider && <UiDivider key={index} type="vertical" />}
      </React.Fragment>
    );
  };

  return <menu className={classNames(styles.uiBadgePanel, className)}>{options.map(renderOption)}</menu>;
};
