import classNames from 'classnames';
import React, { HTMLAttributes, PropsWithChildren, forwardRef } from 'react';

import styles from './UiButtonDecorator.scss';

export type UiButtonDecoratorProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

export const UiButtonDecorator = forwardRef<HTMLDivElement, UiButtonDecoratorProps>((props, ref) => {
  const { children, className, ...rest } = props;

  return (
    <div
      className={classNames(styles.uiButtonDecorator, className)}
      ref={ref}
      role="button"
      tabIndex={0}
      {...rest}
    >
      {children}
    </div>
  );
});
