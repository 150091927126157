import classNames from 'classnames';
import React, { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react';

import styles from './UiCardHeader.scss';
import { UiCardHeaderBack, UiCardHeaderBackProps } from './back/UiCardHeaderBack';
import { UiCardHeaderTitle, UiCardHeaderTitleProps } from './title/UiCardHeaderTitle';

type CardHeaderBackComponent = FC<UiCardHeaderBackProps>;
type CardHeaderTitleComponent = FC<UiCardHeaderTitleProps>;

type HeaderComposition = {
  Back: CardHeaderBackComponent;
  Title: CardHeaderTitleComponent;
};

export interface UiCardHeaderProps {
  className?: string;
  action?: ReactNode;
  style?: CSSProperties;
  emptyPadding?: boolean;
  hasBottomBorder?: boolean;
}

export type UiCardHeaderComponent = FC<PropsWithChildren<UiCardHeaderProps>> & HeaderComposition;

export const UiCardHeader: UiCardHeaderComponent = (props) => {
  const { action, children, className, emptyPadding = false, hasBottomBorder = false, style } = props;
  const classNameStyles = classNames(className, styles.uiCardHeader, {
    [styles.uiCardHeader_emptyPadding]: emptyPadding,
    [styles.uiCardHeader_bottomBorder]: hasBottomBorder,
  });

  return (
    <header style={style} className={classNameStyles}>
      {children}
      <span className={classNames({ [styles.uiCardHeader__action]: action })}>{action}</span>
    </header>
  );
};

UiCardHeader.Back = UiCardHeaderBack;
UiCardHeader.Title = UiCardHeaderTitle;
