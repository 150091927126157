import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import { UseFullscreenResult } from '../../hooks';
import { UiButton, UiButtonDecoratorProps } from '../button';

export interface UiFullscreenProps extends Pick<UiButtonDecoratorProps, 'className' | 'onClick'> {
  fullscreen: UseFullscreenResult;
}

export const UiFullscreen: FC<PropsWithChildren<UiFullscreenProps>> = (props) => {
  const { fullscreen, children, className, onClick } = props;

  return (
    <UiButton.Decorator
      ref={fullscreen.ref}
      onClick={onClick}
      style={fullscreen.isEnabled ? { height: '100%', width: '100%' } : undefined}
      className={classNames('fullscreen', className)}
    >
      {children}
    </UiButton.Decorator>
  );
};
