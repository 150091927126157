import classNames from 'classnames';
import React, { FC, PropsWithChildren, HTMLAttributes, useLayoutEffect, useCallback, useRef } from 'react';

import { useOnClickOutside } from '../../../hooks';
import { useBreakpoint } from '../Layout';
import { LAYOUT_NAVBAR_BREAKPOINT, LAYOUT_NAVBAR_COLLAPSED_BREAKPOINT } from '../constants';
import { useLayout } from '../hooks';
import styles from './LayoutNavbar.scss';
import { LayoutNavbarCollapsed } from './collapsed';

export interface LayoutNavbarProps extends HTMLAttributes<HTMLDivElement> {
  isCollapsed: boolean;
  toggleNavbar: (collapsed?: boolean) => void;
}

export const LayoutNavbar: FC<PropsWithChildren<LayoutNavbarProps>> = (props) => {
  const { children, className, isCollapsed, toggleNavbar, ...rest } = props;
  const { hasHeader } = useLayout();
  const breakpoints = useBreakpoint();
  const containerRef = useRef<HTMLDivElement>(null);

  const isNavbarCollapsed =
    breakpoints[LAYOUT_NAVBAR_COLLAPSED_BREAKPOINT] && !breakpoints[LAYOUT_NAVBAR_BREAKPOINT];

  useLayoutEffect(() => {
    if (breakpoints[LAYOUT_NAVBAR_BREAKPOINT] !== undefined) {
      toggleNavbar(!breakpoints[LAYOUT_NAVBAR_BREAKPOINT]);
    }
  }, [breakpoints[LAYOUT_NAVBAR_BREAKPOINT]]);

  const onClickOutside = useCallback(() => {
    if (!isCollapsed) {
      toggleNavbar();
    }
  }, [breakpoints, toggleNavbar, isCollapsed]);

  useOnClickOutside(containerRef, onClickOutside);

  const expandedClassName = !breakpoints.md
    ? styles.layoutNavbar_expanded_full
    : styles.layoutNavbar_expanded;

  const containerClassName = classNames(
    styles.layoutNavbar,
    styles.layoutNavbar_fixed,
    hasHeader && styles.layoutNavbar_hasHeader,
  );

  const navbar = (
    <div
      {...rest}
      ref={breakpoints[LAYOUT_NAVBAR_BREAKPOINT] ? undefined : containerRef}
      className={classNames(
        containerClassName,
        isCollapsed ? styles.layoutNavbar_collapsed : expandedClassName,
        className,
      )}
    >
      {children}
    </div>
  );

  if (isNavbarCollapsed) {
    return (
      <LayoutNavbarCollapsed containerClassName={containerClassName} toggleNavbar={toggleNavbar}>
        {navbar}
      </LayoutNavbarCollapsed>
    );
  }

  return navbar;
};
