import React, { FC } from 'react';

import { UiButton, UiButtonProps, UiIcon, UiIconProps } from '../../../..';
import { variables } from '../../../../providers/theme';

const IMAGE_PREVIEW_BUTTON_PROPS: UiButtonProps = {
  type: 'link-secondary',
  disabledFocus: true,
};

interface Props extends Pick<UiButtonProps, 'onClick' | 'disabled'> {
  icon: UiIconProps['component'];
}

export const UiImagePreviewButton: FC<Props> = (props) => {
  const { icon, onClick } = props;

  return (
    <UiButton
      {...IMAGE_PREVIEW_BUTTON_PROPS}
      icon={
        <UiIcon
          width={20}
          height={20}
          color={variables.themePalette.colorIcon}
          style={{ margin: 12 }}
          component={icon}
        />
      }
      onClick={onClick}
    />
  );
};
