import { Steps as AntSteps, StepsProps as AntStepsProps, StepProps as AntStepProps } from 'antd';
import { ComponentProps, FC } from 'react';

interface StepsProps extends FC<AntStepsProps> {
  Step: FC<AntStepProps>;
}

export type UiStepsProps = ComponentProps<typeof AntSteps>;

export const UiSteps = AntSteps as unknown as StepsProps;
