import React, { FC } from 'react';

import { UiTypography } from '../../typography';

type Props = {
  total: number;
};

export const UiPaginationTotal: FC<Props> = (props) => {
  const { total } = props;

  return (
    <UiTypography.Text>
      из <UiTypography.Text strong>{total}</UiTypography.Text>
    </UiTypography.Text>
  );
};
