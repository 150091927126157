import { Switch } from 'antd';
import { SwitchProps } from 'antd/es/switch';
import React, { FC, isValidElement, ReactNode } from 'react';

import { useSpace } from '../../providers/space';
import { UiSpace } from '../space';
import { UiTypography } from '../typography';
import { UiSwitchGroup } from './group';

export interface UiSwitchProps extends SwitchProps {
  label?: ReactNode;
}

export interface UiSwitchComponent extends FC<UiSwitchProps> {
  Group: typeof UiSwitchGroup;
}

export const UiSwitch: UiSwitchComponent = (props) => {
  const { label, className, disabled, ...restProps } = props;
  const { spaceXS } = useSpace();
  const isLabelNode = isValidElement(label);

  return (
    <UiSpace size={spaceXS} className={className}>
      <Switch disabled={disabled} {...restProps} />
      {isLabelNode && label}
      {!isLabelNode && <UiTypography.Text disabled={disabled}>{label}</UiTypography.Text>}
    </UiSpace>
  );
};

UiSwitch.Group = UiSwitchGroup;
