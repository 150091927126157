import { Table as AntTable } from 'antd';
import {
  TableProps as AntTableProps,
  TablePaginationConfig as AntTablePaginationConfig,
  ColumnType as AntTableColumnType,
  ColumnGroupType as AntTableColumnGroupType,
} from 'antd/es/table';
import {
  FilterConfirmProps as AntTableFilterConfirmProps,
  SorterResult as AntTableSorterResult,
  TableRowSelection as AntTableRowSelection,
} from 'antd/es/table/interface.js';
import classNames from 'classnames';
import React, { ReactNode, useMemo, useCallback, isValidElement } from 'react';

import TableFilterSVG from '@vkph/ui/svg/table-filter.svg';

import { UiButton } from '../button';
import { UiIcon } from '../icon';
import { UiSpinner } from '../spinner';
import styles from './UiTable.scss';
import { UiTableActionsPanel } from './actions-panel';
import { UiTableCellAvatar } from './cell-avatar/UiTableCellAvatar';
import { UiTableCellDash } from './cell-dash/UiTableCellDash';
import { UiTableColumnSearch, UiTableColumnFilter } from './column';

type ColumnRender<RecordType> = Required<Pick<AntTableColumnType<RecordType>, 'render'>>['render'];
type ColumnRenderReturn<RecordType> = ReturnType<ColumnRender<RecordType>>;
type UiColumnRender<RecordType> = {
  (
    column: ColumnType<RecordType>,
    ...args: Parameters<ColumnRender<RecordType>>
  ): ColumnRenderReturn<RecordType>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface UiTableProps<T extends object = any>
  extends Omit<AntTableProps<T>, 'rowClassName' | 'loading' | 'columns'> {
  loading?: boolean;
  activeRow?: boolean;
  rowClassName?: string;
  disabled?: boolean;
  columns?: ColumnsType<T>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UiTable = <RecordType extends Record<string, any>>(props: UiTableProps<RecordType>) => {
  const { className, rowClassName, activeRow, columns, pagination, loading, onRow, disabled } = props;

  const renderColumn = useCallback<UiColumnRender<RecordType>>(
    (column, ...args) => {
      const { render, clickable } = column || {};
      const renderedColumn = render?.(...args);
      const isRowClickable = clickable && isValidElement(renderedColumn) && onRow;

      if (isRowClickable) {
        return (
          <UiButton.Decorator
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            {renderedColumn}
          </UiButton.Decorator>
        );
      }

      return renderedColumn;
    },
    [onRow],
  );

  const tableColumns = useMemo<UiTableProps<RecordType>['columns']>(() => {
    return columns?.map((column) => ({
      ...column,
      filterIcon: column.filterIcon || <UiIcon width={20} height={20} component={TableFilterSVG} />,
      render: column?.render ? (...args) => renderColumn(column, ...args) : undefined,
    }));
  }, [columns, renderColumn]);

  return (
    <AntTable
      {...props}
      loading={{ indicator: UiSpinner.Indicator, spinning: Boolean(loading), size: 'large' }}
      columns={tableColumns}
      pagination={pagination}
      className={classNames(
        styles.uiTable,
        {
          [styles.uiTableOverflow]: !pagination,
          [styles.uiTable_disabled]: disabled,
        },
        className,
      )}
      rowClassName={classNames(
        {
          [styles.uiTable__row]: activeRow,
        },
        rowClassName,
      )}
    />
  );
};

UiTable.CellDash = UiTableCellDash;
UiTable.CellAvatar = UiTableCellAvatar;
UiTable.ActionsPanel = UiTableActionsPanel;
UiTable.ColumnSearch = UiTableColumnSearch;
UiTable.ColumnFilter = UiTableColumnFilter;

type ColumnClickableParams = {
  clickable?: boolean;
};

export type ColumnType<T> = AntTableColumnType<T> & ColumnClickableParams;
export type TableColumnGroupType<T> = AntTableColumnGroupType<T> & ColumnClickableParams;
export type ColumnsType<T = unknown> = (TableColumnGroupType<T> | ColumnType<T>)[];

export type TableRowSelection<T> = AntTableRowSelection<T>;

export type FilterConfirmProps = AntTableFilterConfirmProps;
export type SorterResult<T> = AntTableSorterResult<T>;

export type TableDataCells = Record<string, ReactNode>;

export type TableData = {
  key: string | number;
} & TableDataCells;

export type TablePaginationConfig = AntTablePaginationConfig;
