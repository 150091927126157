import { QRCode, QRCodeProps } from 'antd';
import React, { FC } from 'react';

import { variables } from '../../providers/theme';

export type UiQRCodeProps = QRCodeProps;

export const UiQRCode: FC<UiQRCodeProps> = (props) => (
  <QRCode
    bordered={false}
    bgColor={variables.themePalette.white}
    color={variables.themePalette.black}
    {...props}
  />
);
