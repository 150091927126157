import React, { FC } from 'react';

import { UiEmpty, UiEmptyFeedProps } from '../../empty';

export const LayoutNoAccess: FC<UiEmptyFeedProps> = (props) => {
  const {
    emptyMessage = { header: 'Нет доступа', description: 'Вы не можете просматривать этот раздел' },
    ...rest
  } = props;

  return <UiEmpty.Feed emptyMessage={emptyMessage} {...rest} />;
};
