import { Button as AntButton } from 'antd';
import { ButtonProps } from 'antd/es/button/button';
import classNames from 'classnames';
import React, { FC, MouseEvent } from 'react';

import styles from './UiButton.scss';
import { UiButtonDecorator } from './decorator/UiButtonDecorator';

declare const ButtonTypes: [
  'primary',
  'secondary',
  'tertiary',
  'tertiary-transparent',
  'link',
  'link-secondary',
  'link-negative',
  'default',
  'action-label',
  'action',
  'danger',
];
export declare type ButtonType = typeof ButtonTypes[number];

enum BtnType {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  TertiaryTransparent = 'tertiary-transparent',
  Link = 'link',
  LinkSecondary = 'link-secondary',
  LinkNegative = 'link-negative',
  ActionLabel = 'action-label',
  Action = 'action',
  Danger = 'danger',
}

export interface UiButtonProps extends Omit<ButtonProps, 'type'> {
  label?: string;
  disabledFocus?: boolean;
  type?: ButtonType;
  round?: boolean;
}

type UiButtonComposition = {
  Decorator: typeof UiButtonDecorator;
};

export type UiButtonComponent = FC<UiButtonProps> & UiButtonComposition;

export const UiButton: UiButtonComponent = (props) => {
  const { label, type, children, className, disabledFocus, onMouseDown, round, ...btnProps } = props;
  const isActive = (key: string) => type === key;

  const classNameStyles = classNames(
    styles.uiButton,
    {
      [styles.uiButton__primary]: isActive(BtnType.Primary),
      [styles.uiButton__secondary]: isActive(BtnType.Secondary),
      [styles.uiButton__tertiary]: isActive(BtnType.Tertiary) || isActive(BtnType.TertiaryTransparent),
      [styles.uiButton__tertiary_transparent]: isActive(BtnType.TertiaryTransparent),
      [styles.uiButton__link]:
        isActive(BtnType.Link) || isActive(BtnType.LinkSecondary) || isActive(BtnType.LinkNegative),
      [styles.uiButton__linkSecondary]: isActive(BtnType.LinkSecondary),
      [styles.uiButton__linkNegative]: isActive(BtnType.LinkNegative),
      [styles.uiButton__actionLabel]: isActive(BtnType.ActionLabel),
      [styles.uiButton__action]: isActive(BtnType.Action),
      [styles.uiButton__danger]: isActive(BtnType.Danger),
      [styles.uiButton_round]: round,
    },
    className,
  );

  const onMouseDownHandler = (e: MouseEvent<HTMLButtonElement>) => {
    if (disabledFocus && e?.preventDefault) e?.preventDefault();

    if (onMouseDown) onMouseDown(e);
  };

  return (
    <AntButton className={classNameStyles} {...btnProps} onMouseDown={onMouseDownHandler}>
      {children || label}
    </AntButton>
  );
};

UiButton.Decorator = UiButtonDecorator;
