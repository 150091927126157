import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import { BadgeColors, UiBadge } from '../../badge';
import styles from './UiTableActionsPanel.scss';

type UiTableActionsPanelProps = {
  count: number;
  className?: string;
};

export const UiTableActionsPanel: FC<PropsWithChildren<UiTableActionsPanelProps>> = (props) => {
  const { count, children, className } = props;

  return (
    <span className={classNames(styles.uiTableActionsPanel, className)}>
      <UiBadge color={BadgeColors.Brand} count={count} />
      {children}
    </span>
  );
};
