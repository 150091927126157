import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import React, { FC, ReactNode, ChangeEvent, useState } from 'react';

import TableFilterSVG from '@vkph/ui/svg/table-filter.svg';

import { UiButton } from '../../../button';
import { UiCheckbox } from '../../../checkbox';
import { UiDivider } from '../../../divider';
import { UiEmpty } from '../../../empty';
import { UiCol, UiRow } from '../../../grid';
import { UiIcon } from '../../../icon';
import { UiInput } from '../../../input';
import { UiList } from '../../../list';
import styles from './UiTableColumnFilter.scss';

const UiTableColumnFilterIcon = () => <UiIcon component={TableFilterSVG} width={20} height={20} />;

type UiTableColumnFilterComposition = {
  Icon: typeof UiTableColumnFilterIcon;
};

export type UiTableColumnFilterItem = {
  value: string;
  label: ReactNode;
};

export interface UiTableColumnFilterProps {
  loading?: boolean;
  error?: boolean;
  options: UiTableColumnFilterItem[];
  onSearch: (query: string) => void;
  onChange: (values: string[]) => void;
  filterDropdownProps: FilterDropdownProps;
  multiple?: boolean;
}

export const UiTableColumnFilter: FC<UiTableColumnFilterProps> & UiTableColumnFilterComposition = (props) => {
  const { loading, error, options, onSearch, onChange, filterDropdownProps, multiple = true } = props;
  const { confirm } = filterDropdownProps;

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const onClear = () => {
    setSelectedValues([]);
    onChange([]);
    confirm({ closeDropdown: true });
  };

  const onSave = () => {
    onChange(selectedValues);
    confirm({ closeDropdown: true });
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedValues([]);
    onSearch(event.target.value);
  };

  const onCheckboxChange = (item: UiTableColumnFilterItem) => (event: CheckboxChangeEvent) => {
    const { checked } = event.target;

    if (checked) {
      setSelectedValues((values) => [...values, item.value]);

      if (!multiple) {
        setSelectedValues(() => [item.value]);
      }
    } else {
      setSelectedValues((values) => values.filter((value) => item.value !== value));
    }
  };

  const renderItem = (item: UiTableColumnFilterItem) => {
    const { value, label } = item;

    return (
      <UiList.Item>
        <UiCol flex="auto">
          <UiRow wrap={false} justify="space-between" align="middle">
            {label}
            <UiCheckbox checked={selectedValues.includes(value)} onChange={onCheckboxChange(item)} />
          </UiRow>
        </UiCol>
      </UiList.Item>
    );
  };

  return (
    <UiCol className={styles.uiTableColumnFilter}>
      <UiRow padding={8}>
        <UiInput.Search onChange={onSearchChange} allowClear size="middle" placeholder="Поиск" />
      </UiRow>
      <UiDivider emptyMargin />
      {error && <UiEmpty.Feed emptyMessage={{ header: 'Произошла ошибка' }} />}
      {!error && (
        <UiList
          className={styles.uiTableColumnFilter__list}
          loading={loading}
          split={false}
          size="small"
          dataSource={options}
          style={{ padding: '12px 0px' }}
          renderItem={renderItem}
        />
      )}
      <UiDivider emptyMargin />
      <UiRow justify="space-between" align="middle" padding={8}>
        <UiButton label="Очистить" size="small" type="tertiary" onClick={onClear} />
        <UiButton label="Сохранить" size="small" type="primary" onClick={onSave} />
      </UiRow>
    </UiCol>
  );
};

UiTableColumnFilter.Icon = UiTableColumnFilterIcon;
