import { Grid, Layout as AntLayout, LayoutProps as AntLayoutProps } from 'antd';
import React, { FC, PropsWithChildren, ReactNode, useMemo } from 'react';

import { useToggle } from '../../hooks';
import { variables } from '../../providers/theme';
import { LayoutBreadcrumb } from './breadcrumb';
import { LAYOUT_NAVBAR_BREAKPOINT, LAYOUT_NAVBAR_COLLAPSED_BREAKPOINT } from './constants';
import { LayoutContent } from './content';
import { LayoutHeader } from './header';
import { LayoutContext, LayoutContextValue } from './hooks';
import { LayoutLoading } from './loading';
import { LayoutMain } from './main';
import { LayoutNavbar } from './navbar';
import { LayoutNoAccess } from './no-access';
import { LayoutSider } from './sider';

type LayoutComposition = {
  Header: typeof LayoutHeader;
  Navbar: typeof LayoutNavbar;
  Sider: typeof LayoutSider;
  Content: typeof LayoutContent;
  Main: typeof LayoutMain;
  Breadcrumb: typeof LayoutBreadcrumb;
  Loading: typeof LayoutLoading;
  NoAccess: typeof LayoutNoAccess;
};

interface Props extends AntLayoutProps {
  header?: ReactNode;
  navbar?: ReactNode;
}

export const Layout: FC<PropsWithChildren<Props>> & LayoutComposition = (props) => {
  const { children, header, navbar, ...rest } = props;
  const breakpoints = Grid.useBreakpoint();
  const [isNavbarCollapsed, toggleIsNavbarCollapsed] = useToggle([false, true]);

  const hasHeader = Boolean(header);
  const hasNavbar = Boolean(navbar);

  const contextValue = useMemo<LayoutContextValue>(() => {
    const { layoutSizes } = variables;

    const outerGutter = breakpoints.lg ? layoutSizes.outerGutterLG : layoutSizes.outerGutterXS;
    const innerGutter = breakpoints.lg ? layoutSizes.innerGutterLG : layoutSizes.innerGutterXS;

    return { outerGutter, innerGutter, hasHeader, hasNavbar };
  }, [breakpoints.lg, hasHeader, hasNavbar]);

  const navbarMargin = useMemo(() => {
    if (!hasNavbar) {
      return undefined;
    }

    if (breakpoints[LAYOUT_NAVBAR_BREAKPOINT]) {
      return variables.layoutSizes.navbarWidth;
    }

    if (breakpoints[LAYOUT_NAVBAR_COLLAPSED_BREAKPOINT]) {
      return variables.layoutSizes.navbarCollapsedWidth;
    }

    return undefined;
  }, [hasNavbar, breakpoints]);

  return (
    <LayoutContext.Provider value={contextValue}>
      <AntLayout {...rest}>
        {hasHeader && (
          <LayoutHeader isNavbarCollapsed={isNavbarCollapsed} toggleNavbar={toggleIsNavbarCollapsed}>
            {header}
          </LayoutHeader>
        )}
        <AntLayout style={{ marginTop: hasHeader ? variables.layoutSizes.headerHeight : undefined }}>
          {hasNavbar && (
            <LayoutNavbar isCollapsed={isNavbarCollapsed} toggleNavbar={toggleIsNavbarCollapsed}>
              {navbar}
            </LayoutNavbar>
          )}
          <AntLayout style={{ marginLeft: navbarMargin }}>{children}</AntLayout>
        </AntLayout>
      </AntLayout>
    </LayoutContext.Provider>
  );
};

Layout.Header = LayoutHeader;
Layout.Navbar = LayoutNavbar;
Layout.Sider = LayoutSider;
Layout.Content = LayoutContent;
Layout.Main = LayoutMain;
Layout.Breadcrumb = LayoutBreadcrumb;
Layout.Loading = LayoutLoading;
Layout.NoAccess = LayoutNoAccess;

export const { useBreakpoint } = Grid;
