import { createContext } from 'react';

import { UiModalStyleType } from '..';

export type UiModalContextState = {
  onClose: () => void;
  onAfterClose?: () => void;
};

export const UiModalContext = createContext<UiModalContextState>({ onClose: () => undefined }); // TODO: придумать как управлять состоянием isOpen через провайдет, или как сделать destroy текущего AntModal

export type UiModalStyleContextState = {
  type?: UiModalStyleType;
};

export const UiModalStyleContext = createContext<UiModalStyleContextState>({});
