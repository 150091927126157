import classNames from 'classnames';
import React, { FC } from 'react';

import { UiCol, UiColProps, UiRow, UiRowProps } from '../../grid';
import { UiSpace, UiSpaceProps } from '../../space';
import { UiTypography, UiTypographyTextProps } from '../../typography';
import styles from './UiDescriptionsItem.scss';

export interface UiDescriptionsItemProps extends UiColProps {
  spaceProps?: UiSpaceProps;
  label?: string;
  labelProps?: UiTypographyTextProps;
  labelRowProps?: UiRowProps;
}

export const UiDescriptionsItem: FC<UiDescriptionsItemProps> = (props) => {
  const { children, label, spaceProps, labelProps, labelRowProps, ...rest } = props;
  const { className, ...restSpaceProps } = spaceProps || {};

  return (
    <UiCol {...rest}>
      <UiSpace
        direction="vertical"
        size={4}
        className={classNames(styles.uiDescriptionsItem_space, className)}
        {...restSpaceProps}
      >
        {Boolean(label) && (
          <UiRow {...labelRowProps}>
            <UiTypography.Text type="secondary" {...labelProps}>
              {label}
            </UiTypography.Text>
          </UiRow>
        )}
        {children}
      </UiSpace>
    </UiCol>
  );
};
