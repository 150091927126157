import { InputProps } from 'antd/es/input';
import classNames from 'classnames';
import noop from 'lodash/noop';
import React, { useCallback, useState, FC, ChangeEvent } from 'react';

import AddSvg from '@vkph/ui/svg/add.svg';
import RemoveSvg from '@vkph/ui/svg/remove.svg';

import { UiButton } from '../button';
import { UiIcon } from '../icon';
import { UiInput } from '../input';
import styles from './UiInputNumber.scss';

export interface UiInputNumberProps extends Omit<InputProps, 'onChange' | 'min' | 'max'> {
  value?: number;
  step?: number;
  min?: number;
  max?: number;
  onChange?: (value: number) => void;
  precision?: number;
}

const getValueWithoutWords = (text: string): number => {
  return Number(text.replace(/[^0-9.-]+/, ''));
};

export const UiInputNumber: FC<UiInputNumberProps> = (props) => {
  const {
    className,
    value = 0,
    step = 1,
    min = 0,
    max = Number.MAX_SAFE_INTEGER,
    disabled,
    onChange = noop,
    precision = 0,
    ...otherProps
  } = props;

  const [inputValue, setInputValue] = useState<string | number>(value);

  const disabledAddonAfter = value >= max || disabled;
  const disabledAddonBefore = disabled || value === min;

  const onDecrement = useCallback(() => {
    const decrementedValue = Number((value - step).toFixed(precision));

    setInputValue(decrementedValue);
    onChange(decrementedValue);
  }, [onChange, value, step, precision]);

  const onIncrement = useCallback(() => {
    const incrementedValue = Number((value + step).toFixed(precision));

    setInputValue(incrementedValue);
    onChange(incrementedValue);
  }, [onChange, value, step, precision]);

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const valueFromEvent = e.target.value;

      setInputValue(valueFromEvent);

      if (!Number.isNaN(valueFromEvent)) {
        onChange(valueFromEvent);
      }
    },
    [onChange],
  );
  const onBlurHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const valueFromEvent = e.target.value;
      const valueWithoutWords = getValueWithoutWords(valueFromEvent);

      let validNumberValue = Number(valueWithoutWords.toFixed(precision));

      if (validNumberValue < min || Number.isNaN(validNumberValue)) {
        validNumberValue = min;
      }

      if (validNumberValue > Number(max)) {
        validNumberValue = Number(max);
      }

      setInputValue(validNumberValue);
      onChange(validNumberValue);
    },
    [min, onChange, precision, max],
  );

  const AddonBefore = (
    <UiButton
      type="link-secondary"
      onClick={onDecrement}
      disabled={disabledAddonBefore}
      icon={<UiIcon component={RemoveSvg} width={20} height={20} />}
    />
  );

  const AddonAfter = (
    <UiButton
      type="link-secondary"
      onClick={onIncrement}
      disabled={disabledAddonAfter}
      icon={<UiIcon component={AddSvg} width={20} height={20} />}
    />
  );

  return (
    <UiInput
      {...otherProps}
      min={min}
      max={max}
      type="text"
      value={inputValue}
      disabled={disabled}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
      addonBefore={AddonBefore}
      addonAfter={AddonAfter}
      className={classNames(styles.uiInputNumber, { [styles.uiInputNumber_disabled]: disabled }, className)}
    />
  );
};
